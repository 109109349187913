import React from "react";
import { useMobileViewStore } from "stores/useMobileViewStore";

function Close() {
  // Only on mobile
  const closeMain = useMobileViewStore((state) => state.closeMain);

  return (
    <div className="col-3 d-xl-none">
      <ul className="list-inline mb-0">
        <li className="list-inline-item">
          <a
            className="text-muted px-0"
            href="#/"
            data-chat="open"
            onClick={(e) => {
              e.preventDefault();
              closeMain();
            }}
          >
            <i className="icon-md fe-chevron-left"></i>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Close;
