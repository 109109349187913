import React from "react";

function SubmitButton({
  message,
  sendMessage
}: {
  message: string;
  sendMessage: () => Promise<void>
}) {

  return (
    <div className="col-auto">
      <button
        className="btn btn-ico btn-primary rounded-circle"
        type="button"
        onClick={sendMessage}
      >
        <span className="fe-send"></span>
      </button>
    </div>
  );
}

export default SubmitButton;
