export default function timeSince(date: Date | null, trunc=false) {
  if(date) {  
    // @ts-ignore
    var seconds = Math.floor((new Date() - date) / 1000);
  
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      const intervalFloor = Math.floor(interval);
      return intervalFloor + `${trunc ? 'y' : ` year${intervalFloor === 1 ? '' : 's'} ago`}`;
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      const intervalFloor = Math.floor(interval);
      return intervalFloor + `${trunc ? 'mo' : ` month${intervalFloor === 1 ? '' : 's'} ago`}`;
    }
    interval = seconds / 86400;
    if (interval > 1) {
      const intervalFloor = Math.floor(interval);
      return intervalFloor + `${trunc ? 'd' : ` day${intervalFloor === 1 ? '' : 's'} ago`}`;
    }
    interval = seconds / 3600;
    if (interval > 1) {
      const intervalFloor = Math.floor(interval);
      return intervalFloor + `${trunc ? 'h' : ` hour${intervalFloor === 1 ? '' : 's'} ago`}`;
    }
    interval = seconds / 60;
    if (interval > 1) {
      const intervalFloor = Math.floor(interval);
      return intervalFloor + `${trunc ? 'm' : ` minute${intervalFloor === 1 ? '' : 's'} ago`}`;
    }
  
    if(seconds > 1) {
      const secondsFloor = Math.floor(seconds)
      return secondsFloor + `${trunc ? 's' : ` second${secondsFloor === 1 ? '' : 's'} ago`}`;
    }
    return ""
  } else {
    return ""
  }
}
