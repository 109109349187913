import { useEffect } from "react";
import SendBird from "sendbird";
import { v4 as uuidv4 } from "uuid";

import { useProfileStore } from "stores/useProfileStore";

function useHandleUserEvents(sb: SendBird.SendBirdInstance) {
  const setCurrentUserStatus = useProfileStore(
    (state) => state.setCurrentUserStatus
  );

  useEffect(() => {
    const userHandlerId = uuidv4();
    const connectionHandler = new sb.ConnectionHandler();

    connectionHandler.onReconnectStarted = () => {
      setCurrentUserStatus();
    };
    connectionHandler.onReconnectSucceeded = async () => {
      setCurrentUserStatus();
    };

    connectionHandler.onReconnectFailed = () => {
      sb.reconnect();
      setCurrentUserStatus();
      // retry connecting forever
    };
    sb.addConnectionHandler(userHandlerId, connectionHandler);

    return () => {
      sb.removeConnectionHandler(userHandlerId);
    };
  }, [sb, setCurrentUserStatus]);
}

export default useHandleUserEvents;
