import React from 'react'
// @ts-ignore
import LoadingVideo from "../img/logo-loading-120.mp4"

function LogoLoading() {
  return (
    <video style={{width: "120px", height: "120px"}} autoPlay loop muted>
    <source src={LoadingVideo} type="video/mp4" />
  </video>
  )
}

export default LogoLoading
