import create, { SetState, GetState } from "zustand";

/**
 * this store is used to manage when we open the app with context to a new chat
 * - eg clicking on a button through the chrome extension
 */

type NewChatStore = {
  username: string;
  profilePicUrl: string;
  walletAddress: string;
  originUrl: string;

  setNewChat: (
    username: string,
    profilePicUrl: string,
    walletAddress: string,
    originUrl: string
  ) => void;
};

const useNewChatStore = create<NewChatStore>(
  (set: SetState<NewChatStore>, get: GetState<NewChatStore>) => {
    return {
      username: "",
      profilePicUrl: "",
      walletAddress: "",
      originUrl: "",

      setNewChat: (
        username: string,
        profilePicUrl: string,
        walletAddress: string,
        originUrl: string
      ) => {
        set({
          username: username,
          profilePicUrl: profilePicUrl,
          walletAddress: walletAddress,
          originUrl: originUrl,
        });
      },
    };
  }
);

export default useNewChatStore;
