import React, { useEffect, useReducer, ReactNode } from "react";
import SendBird from "sendbird";

import ChatsTitle from "./ChatsTitle";
import ChatsLinkList from "./ChatsLinkList";
import ChatsLinkLoading from "./ChatsLinkLoading";
import MessageRequestButton from "./MessageRequestButton";
import User from "./User";

import chatsListInitialState from "./dux/initialState";
import chatsListReducers from "./dux/reducers";

import useInitialChatsList from "./hooks/useInitialChatsList";
import useHandleChatListsEvent from "./hooks/useHandleChatsListEvents";
import useHandlePubSubEvents from "./hooks/useHandlePubSubEvents";
import useResetFriends from "./hooks/useResetFriends";
import useResetChannelsCallback from "./hooks/useResetChannelsCallback";
import useUserProfiles from "./hooks/useUserProfiles";

import { SidebarType } from "stores/useSidebarStore";
import { useChatStore } from "stores/useChatStore";
import { useFocusedChatStore } from "stores/useFocusedChatStore";
import { useFriendsStore } from "stores/useFriendsStore";

import {
  filterChannelsWithoutBlockedMembers,
  filterChannelsByFriends,
} from "utils/chat/util";

function ChatsList({
  activeSidebar,
  openNav,
}: {
  activeSidebar: SidebarType;
  openNav: (nav: SidebarType) => void;
}) {
  const sb = SendBird.getInstance();
  const [chatsListStore, chatsListDispatcher] = useReducer(
    chatsListReducers,
    chatsListInitialState
  );
  const { loading, allChannels } = chatsListStore;

  const setFocusedChannel = useFocusedChatStore(
    (state) => state.setFocusedChannel
  );
  const friends = useFriendsStore((state) => state.friends);

  useResetFriends();
  useInitialChatsList(chatsListDispatcher);
  useHandleChatListsEvent(sb, chatsListDispatcher);
  useHandlePubSubEvents(chatsListDispatcher);

  // this runs at an interval
  useResetChannelsCallback(allChannels, chatsListDispatcher);
  useUserProfiles(allChannels);

  // filter the channels and remove all channels that contain a user that is blocked
  const channelsWithoutBlockedMembers = filterChannelsWithoutBlockedMembers(
    allChannels
  );

  // filter the channels based on the current display
  // - for now, we consider a `requested` channel one where it does not
  //   contain any of my friends
  // - defaults to setting only channels with friends
  const { withFriends, withNoFriends } = filterChannelsByFriends(
    channelsWithoutBlockedMembers,
    friends
  );
  let displayChannels = withFriends;
  if (activeSidebar === SidebarType.CHATS) {
    displayChannels = withFriends;
  } else if (activeSidebar === SidebarType.REQUESTS) {
    displayChannels = withNoFriends;
  }

  // calculate the total number of unread messages
  let numUnreadMsgsFromNotFriends = 0;
  withNoFriends.forEach((channel) => {
    numUnreadMsgsFromNotFriends += channel.unreadMessageCount;
  });
  let numUnreadMsgsFromFriends = 0;
  withFriends.forEach((channel) => {
    numUnreadMsgsFromFriends += channel.unreadMessageCount;
  });

  const setUnreadMessagesFromFriends = useChatStore(
    (state) => state.setUnreadMessagesFromFriends
  );
  useEffect(() => {
    setUnreadMessagesFromFriends(numUnreadMsgsFromFriends);
  }, [setUnreadMessagesFromFriends, numUnreadMsgsFromFriends]);

  return (
    <Container>
      <ChatsTitle
        activeSidebar={activeSidebar}
        resetFocusedChannel={() => {
          setFocusedChannel(null);
        }}
        openSidebarChats={() => {
          openNav(SidebarType.CHATS);
        }}
      />
      {activeSidebar === SidebarType.CHATS ? (
        <MessageRequestButton
          resetFocusedChannel={() => setFocusedChannel(null)}
          openRequests={() => openNav(SidebarType.REQUESTS)}
          numUnreadMsgs={numUnreadMsgsFromNotFriends}
        />
      ) : null}
      {loading ? (
        <ChatsLinkLoading />
      ) : (
        <ChatsLinkList channels={displayChannels} />
      )}
    </Container>
  );
}

function Container({ children }: { children: ReactNode }) {
  return (
    <div className="d-flex flex-column h-full justify-between">
      <div className="hide-scrollbar h-full">
        <div className="container-fluid py-6">{children}</div>
      </div>
      <User />
    </div>
  );
}

export default ChatsList;
