import create, { SetState, GetState } from "zustand";
import pubSubFactory from "utils/pubSub";

// NTF type this
type PubSubStore = {
  pubSub: any;
};

export const usePubSubStore = create<PubSubStore>(
  (set: SetState<PubSubStore>, get: GetState<PubSubStore>) => {
    return {
      pubSub: pubSubFactory(),
    };
  }
);
