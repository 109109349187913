import SendBird, { UserMessage } from "sendbird";
import { immerable } from "immer";
import { getOtherMember } from "./util";
import * as sentry from "utils//sentry";

function getSender(msg: any) {
  return msg.sender || msg._sender;
}

// DEVNOTE: this needs to match the SendBird message status
export enum SendingMessageStatus {
  NONE = "none",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
  PENDING = "pending",
}

export enum UserStatus {
  ONLINE = "online",
  OFFLINE = "offline",
}

export class ChatFormatted {
  [immerable] = true;

  // globally unique id to identify a single chat, not expose on UI
  // uses SendBirds url flag
  id: string;

  // the name that is displayed on the conversations list page
  // will be listed in given priority
  // - sendbird nickname
  // - opposite users userId / walletAddress
  name: string;

  // number of unread messages by the current user in the conversation
  numUnreadMessages: number;

  // status of the opposite user in the chat
  // not relevant for group chats
  status: UserStatus;

  // is the opposite user typing, translates to a
  isTyping: boolean;

  // last message from this chat
  lastMessage: MessageFormatted | null;
  lastMessageSenderId: string;

  // blocks of messages that are sent by a single sender
  // - the latest index = latest message block
  messageFormattedWrappers: MessageFormattedWrapper[];

  constructor(channel: SendBird.GroupChannel) {
    this.id = channel.url;
    this.name = "";
    this.numUnreadMessages = 0;
    this.status = UserStatus.OFFLINE;
    this.isTyping = false;
    this.messageFormattedWrappers = [];
    this.lastMessage = null;
    this.lastMessageSenderId = "";

    this.update(channel);
  }

  /**
   * update the current last message according to the given channel
   * - used for displaying the conversations list
   */
  _updateLastMessage(channel: SendBird.GroupChannel) {
    if (channel.lastMessage?.isUserMessage()) {
      const lastMessage = channel.lastMessage as UserMessage;
      this.lastMessage = new MessageFormatted(
        lastMessage.message,
        lastMessage.createdAt,
        lastMessage.messageId.toString()
      );
      const sender = getSender(lastMessage);
      this.lastMessageSenderId = sender.userId;
    }
  }

  getLastMessageFormattedWrapper() {
    const numMesssageFormattedWrapper = this.messageFormattedWrappers.length;
    if (numMesssageFormattedWrapper === 0) {
      // no messages, return null
      return null;
    }

    return this.messageFormattedWrappers[numMesssageFormattedWrapper - 1];
  }

  /**
   * returns the last formatted message from this chat
   * - returns null if it DNE
   */
  getLastMessageFormatted() {
    const lastMessageFormattedWrapper = this.getLastMessageFormattedWrapper();
    if (!lastMessageFormattedWrapper) {
      return null;
    }
    const numMessages = lastMessageFormattedWrapper.messages.length;
    if (numMessages === 0) {
      return null;
    }
    return lastMessageFormattedWrapper.messages[numMessages - 1];
  }

  getLastSender() {
    const lastMessageFormattedWrapper = this.getLastMessageFormattedWrapper();
    if (!lastMessageFormattedWrapper) {
      return null;
    }
    return lastMessageFormattedWrapper.senderId;
  }

  /**
   * update the current chat with new data from a channel
   * - will throw error if a different channel url is given
   */
  update(channel: SendBird.GroupChannel) {
    if (this.id !== channel.url) {
      sentry.captureGenericMsg("could not update channel", sentry.FATAL);
      throw new Error("unexpected error");
    }

    // look for the member that is NOT the current user
    const otherMember = getOtherMember(channel);

    this.id = channel.url;
    this.name = otherMember.nickname;
    if (this.name === "") {
      this.name = otherMember.userId;
    }
    this.numUnreadMessages = channel.unreadMessageCount;

    switch (otherMember.connectionStatus) {
      case "online":
        this.status = UserStatus.ONLINE;
        break;
      case "offline":
        this.status = UserStatus.OFFLINE;
        break;
    }
    this.isTyping = false;
    this._updateLastMessage(channel);
  }

  /**
   * inserts the message into the current chat
   * - throw error if message does not belong to current channel
   * - will place message in the correct position
   * - throw error if the message already exists
   */
  insertMessage(message: SendBird.UserMessage) {
    const ts = new Date(message.createdAt);

    const lastMessageFormattedWrapper = this.messageFormattedWrappers[
      this.messageFormattedWrappers.length - 1
    ];
    const sender = getSender(message);
    if (
      this.messageFormattedWrappers.length === 0 ||
      (lastMessageFormattedWrapper?.getLatestSentTime() < ts &&
        lastMessageFormattedWrapper.senderId !== sender.userId)
    ) {
      // create new wrapper
      const newMsgWrapper = new MessageFormattedWrapper(
        message.messageId.toString(),
        sender.userId,
        sender.profileUrl
      );
      newMsgWrapper.insertMessage(message);
      this.messageFormattedWrappers.push(newMsgWrapper);
    }

    for (let i = this.messageFormattedWrappers.length - 1; i >= 0; i--) {
      const msgFormattedWrapper = this.messageFormattedWrappers[i];
      // if message exists in the wrapper
      if (msgFormattedWrapper.hasMessage(message)) {
        // message already exists
        return;
      }

      if (msgFormattedWrapper.senderId !== sender.userId) {
        // this is a wrapper for a different sender, skip
        continue;
      }

      if (msgFormattedWrapper.getEarliestSentTime() <= ts) {
        // found a potential block to insert into
        // because the for loop is going backwards, it should be safe to insert
        this.messageFormattedWrappers[i].insertMessage(message);
        return;
      }
    }

    // this means that the message is probably before all the existing messages
    if (this.messageFormattedWrappers[0].senderId === sender.userId) {
      // append to the last one
      this.messageFormattedWrappers[0].insertMessage(message);
      return;
    } else {
      // create a new one as last, this means that the new message is before all current messages, and also should not be attached
      // to the last message wrapper as it has a different sender id
      const newMsgWrapper = new MessageFormattedWrapper(
        message.messageId.toString(),
        sender.userId,
        sender.profileUrl
      );
      newMsgWrapper.insertMessage(message);
      this.messageFormattedWrappers.splice(0, 0, newMsgWrapper);
      return;
    }
  }
}

/**
 * a bunch of messages sent from a single sender
 * - this is mostly used for the UI to able to batch display messages with a single display picture
 */
export class MessageFormattedWrapper {
  [immerable] = true;

  // unique id for the wrapper, the use case for this is to map react elements
  key: string;

  // id of the sender of who sent this, currently walletAddress
  senderId: string;

  // profile url of the person who sent this
  senderProfileUrl: string;

  // list of messages sent by the above user
  messages: MessageFormatted[];

  constructor(messageId: string, senderId: string, senderProfileUrl: string) {
    this.key = messageId;
    this.senderId = senderId;
    this.messages = [];
    this.senderProfileUrl = senderProfileUrl;
  }

  /**
   * check if the given message should be inserted into the current wrapper
   * - sender must be the same
   * - messageId does not exist in the current wrapper
   */
  shouldInsertMessage(message: SendBird.UserMessage) {
    const sender = getSender(message);
    if (sender.userId !== this.senderId) {
      return false;
    }
    if (this.hasMessage(message)) {
      return false;
    }
    return true;
  }

  /**
   * checks if the given message exists in the wrapper
   */
  hasMessage(message: SendBird.UserMessage) {
    for (let i = 0; i < this.messages.length; i++) {
      if (this.messages[i].key === message.messageId.toString()) {
        return true;
      }
    }
    return false;
  }

  /**
   * returns the index which this message should be inserted at
   * - does not validate that the message is valid for insertion
   */
  getIndexForMessage(message: SendBird.UserMessage) {
    // DEVNOTE (optimize): binary serach
    const ts = new Date(message.createdAt);
    for (let i = this.messages.length - 1; i >= 0; i--) {
      if (this.messages[i].timeSent.getTime() < ts.getTime()) {
        return i + 1;
      }
    }
    // looks like everything is earlier, put at top of message list
    return 0;
  }

  /**
   * return the earliest sent time of the wrapper block
   * - will return very early date if no messages
   * - return in Date object
   */
  getEarliestSentTime() {
    if (this.messages.length === 0) {
      const d = new Date();
      d.setFullYear(d.getFullYear() - 500);
      return d;
    }

    return this.messages[0].timeSent;
  }

  /**
   * return the latest sent time of the wrapper block
   * - will return a really late date if no messages
   * - return in Date object
   */
  getLatestSentTime() {
    if (this.messages.length === 0) {
      const d = new Date();
      d.setFullYear(d.getFullYear() + 500);
      return d;
    }
    return this.messages[this.messages.length - 1].timeSent;
  }

  /**
   * insert a message into the wrapper at the correct position
   * - will throw an error if not a valid message to insert
   */
  insertMessage(message: SendBird.UserMessage) {
    if (!this.shouldInsertMessage(message)) {
      // silently return
      sentry.captureGenericMsg(
        "could not insert message into MessageFormattedWrapper",
        sentry.FATAL
      );
      return;
    }
    const newMsgFormatted = new MessageFormatted(
      message.message,
      message.createdAt,
      message.messageId.toString()
    );
    const index = this.getIndexForMessage(message);
    this.messages.splice(index, 0, newMsgFormatted);

    // DEVNOTE: this key is important for scrolling
    // because we insert the latest message last, we do this to ensure
    // that the key is mostly unique
    this.key = message.messageId.toString();
  }
}

export class MessageFormatted {
  [immerable] = true;

  // unique id Message, the use case for this is to map react elements
  // - the value is set to the sendbird messageId
  key: string;

  // actual message
  content: string;

  // time that the message was sent
  timeSent: Date;

  constructor(content: string, timeSentUnixTS: number, key: string) {
    const timeSent = new Date(timeSentUnixTS);
    this.key = key;
    this.content = content;
    this.timeSent = timeSent;
  }
}
