import React, { useState } from "react";

function InputButtonGroup() {
  const [isDropdown, setIsDropdown] = useState(false);

  return (
    <div
      className={`input-group-append ${isDropdown ? "show" : ""}`}
      onClick={() => {}}
      onMouseEnter={() => setIsDropdown(true)}
      onMouseLeave={() => setIsDropdown(false)}
    >
      <button
        className="btn btn-outline-primary dropdown-toggle wallet-toggle"
        style={{
          boxShadow: "inset 0px 0px 0px 1px #edeef6",
          borderWidth: 0,
        }}
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i className="fe-search" />{isDropdown ? <span className="ml-4">Find address</span> : null}
      </button>
      <div
        className={`dropdown-menu ${isDropdown ? "show" : ""}`}
        x-placement="bottom-start"
        style={{
          position: "absolute",
          willChange: "transform",
          top: "0px",
          left: "0px",
          transform: "translate3d(310px, 55px, 0px)",
        }}
      >
        <a
          className="dropdown-item"
          href="https://opensea.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Opensea
        </a>
        <div role="separator" className="dropdown-divider"></div>
        <a
          className="dropdown-item"
          href="https://foundation.app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Foundation
        </a>
        <div role="separator" className="dropdown-divider"></div>
        <a
          className="dropdown-item"
          href="https://tryshowtime.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Showtime
        </a>
        <div role="separator" className="dropdown-divider"></div>
        <a
          className="dropdown-item"
          href="https://rarible.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Rarible
        </a>
      </div>
    </div>
  );
}

export default InputButtonGroup;
