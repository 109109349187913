import create, { SetState, GetState } from "zustand";

export enum ModalType {
  ADD_CHAT = "ADD_CHAT",
  SETTINGS = "SETTINGS",
}

type ModalStore = {
  openedModal: ModalType | null;
  setModal: (newModal: ModalType | null) => void;
};

export const useModalStore = create<ModalStore>(
  (set: SetState<ModalStore>, get: GetState<ModalStore>) => {
    return {
      openedModal: null,
      setModal: (newModal: ModalType | null) => {
        return set({ openedModal: newModal });
      },
    };
  }
);
