import React, { useState } from "react";
import MessageTextArea from "./MessageTextArea";
import SubmitButton from "./SubmitButton";
import FooterContainer from "../FooterContainer";

function SendMessage({
  setAlert,
  sendUserMessage,
}: {
  setAlert: React.Dispatch<
    React.SetStateAction<{
      text: string;
      type: string;
    }>
  >;
  sendUserMessage: (msg: string) => void;
}) {
  const [message, setMessage] = useState("");

  async function sendMessage() {
    if (message === "") {
      // silently no-op when no message is input by user
      return;
    }

    const msgToBeSent = message;
    setMessage("");

    try {
      // DEVNOTE: this error is handled by the dispatcher
      sendUserMessage(msgToBeSent);
    } catch (err) {
      setAlert({ type: "error", text: "could not send message" });
    }
  }

  return (
    <FooterContainer>
      <form id="chat-id-2-form" data-emoji-form="" onSubmit={() => false}>
        <div className="form-row align-items-center">
          <div className="col">
            <div className="input-group">
              <MessageTextArea
                message={message}
                setMessage={setMessage}
                sendMessage={sendMessage}
              />
              {/* <EmojiButton />
                <UploadButton /> */}
            </div>
          </div>
          <SubmitButton message={message} sendMessage={sendMessage} />
        </div>
      </form>
    </FooterContainer>
  );
}

export default SendMessage;
