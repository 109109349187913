import * as Sentry from "@sentry/react";

// indicates breaking events, will cause users to not be able to use the app at all
export const FATAL = Sentry.Severity.Fatal;

// indicate non breaking events, should fix but users will still be able to use the product
export const WARN = Sentry.Severity.Warning;

/**
 * sets the context to the given user id
 */
export function setUser(userId: string | null) {
  if (userId) {
    Sentry.setUser({
      id: userId,
    });
  } else {
    Sentry.configureScope((scope) => scope.setUser(null));
  }
}

export function captureGenericMsg(
  message: string,
  severity: Sentry.Severity,
  extra: any | null = null
) {
  return _captureMessage(
    message,
    severity,
    {
      generic: "generic",
    },
    extra
  );
}

export function captureGenericMsgEx(
  message: string,
  err: Error,
  severity: Sentry.Severity,
  extra: any | null = null
) {
  if (extra) {
    extra["message"] = message;
  } else {
    extra = {
      message: message,
    };
  }
  return _captureException(
    err,
    severity,
    {
      generic: "generic",
    },
    extra
  );
}

export function captureSendBirdMsg(
  message: string,
  severity: Sentry.Severity,
  extra: any | null = null
) {
  return _captureMessage(
    message,
    severity,
    {
      sendbird: "sendbird",
    },
    extra
  );
}

export function captureSendBirdEx(
  message: string,
  err: Error,
  severity: Sentry.Severity,
  extra: any | null = null
) {
  if (extra) {
    extra["message"] = message;
  } else {
    extra = {
      message: message,
    };
  }
  return _captureException(
    err,
    severity,
    {
      sendbird: "sendbird",
    },
    extra
  );
}

function _captureException(
  err: Error,
  severity: Sentry.Severity,
  tags: {},
  extra: any | null = null
) {
  Sentry.captureException(err, {
    level: severity,
    tags: tags,
    extra: extra,
  });
}

function _captureMessage(
  message: string,
  severity: Sentry.Severity,
  tags: {},
  extra: {}
) {
  Sentry.captureMessage(message, {
    level: severity,
    tags: tags,
    extra: extra,
  });
}
