import { Dispatch, useCallback } from "react";
import SendBird from "sendbird";

import { genMessageListQuery } from "utils/chat/action";
import * as sentry from "utils/sentry";

import ActionType from "../dux/actionTypes";

import { useNotificationsStore } from "stores/useNotificationsStore";

/**
 * handle loading older messages for the given channel
 */
function useScrollCallback(
  focusedChannel: SendBird.GroupChannel,
  messagesDispatcher: Dispatch<any>,
  lastMessageTimeStamp: number
) {
  const addNewErrorNotification = useNotificationsStore(
    (state) => state.addNewErrorNotification
  );

  // NTF type the callback
  return useCallback(
    (cb: (messages: any) => void) => {
      const messageListQuery = genMessageListQuery();
      messageListQuery.prevResultSize = 30;

      focusedChannel
        .getMessagesByTimestamp(
          lastMessageTimeStamp || new Date().getTime(),
          messageListQuery
        )
        .then((messages) => {
          const hasMoreMessages = messages && messages.length > 0;
          const lastMessageTs = hasMoreMessages ? messages[0].createdAt : null;

          messagesDispatcher({
            type: ActionType.GET_PREV_MESSAGES_SUCCESS,
            payload: {
              messages,
              hasMore: hasMoreMessages,
              lastMessageTimeStamp: lastMessageTs,
            },
          });
          cb(messages);
        })
        .catch((error) => {
          sentry.captureSendBirdEx(
            "Could not load messages",
            error,
            sentry.FATAL
          );
          addNewErrorNotification("Could not load messages");
        });
    },
    [
      focusedChannel,
      messagesDispatcher,
      lastMessageTimeStamp,
      addNewErrorNotification,
    ]
  );
}

export default useScrollCallback;
