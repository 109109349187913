import React, { useState, useEffect } from "react";
import MetaMaskLogo from "img/metamask-logo.svg";
import { useAuthStore } from "stores/useAuthStore";
import { useLocation, useHistory } from "react-router-dom";

function LoginButton() {
  const [progressPercent, setProgressPercent] = useState(100);
  const [buttonMsg, setButtonMsg] = useState("Login with MetaMask");
  // const [width, setWidth] = useState("100");
  const history = useHistory();
  const location = useLocation();
  // @ts-ignore
  const { from } = location.state || { from: { pathname: "/" } };

  function changeButton(
    msg: string = buttonMsg,
    progress: number = progressPercent
  ): void {
    setButtonMsg(msg);
    setProgressPercent(progress);
  }

  useEffect(() => {
    if (progressPercent === 0) {
      // use replace instead of push because on a `back` event, we want to skip the login page
      setTimeout(() => history.replace(from), 500); // Just so the user can see the successful login
    }
  }, [progressPercent, history, from]);

  useEffect(() => {
    if (!isNotErrorState(buttonMsg)) {
      // change back to default state after 3 seconds
      setTimeout(() => setButtonMsg("Login with MetaMask"), 3000);
    }
  }, [buttonMsg]);

  let metaMaskPosition;
  let progressBarWidth;
  let progressPercentStr = progressPercent.toString();

  if (
    // check if progress percent in the mapping and setting the position
    progressPercentStr in progressBarMappingTranslate &&
    progressPercentStr in progressBarMappingWidth
  ) {
    // @ts-ignore
    metaMaskPosition = progressBarMappingTranslate[progressPercentStr];
    // @ts-ignore
    progressBarWidth = progressBarMappingWidth[progressPercentStr];
  }

  const authLogin = useAuthStore((state) => state.login);
  async function login() {
    if (buttonMsg === "Click to download MetaMask") {
      openInNewTab();
      setButtonMsg("Login with MetaMask");
    } else {
      try {
        await authLogin(changeButton);
        changeButton("Success!", 0);
      } catch (error) {
        console.error(error);
        changeButton(`${error.message}`, 100);
      }
    }
  }

  return (
    <div
      onClick={login}
      className="relative transform rounded-xl hover:-translate-y-0.5 hover:shadow-loginButton transition-all duration-300 ease-loginButton focus:outline-none cursor-pointer"
    >
      <div className="overflow-hidden h-16 w-72 flex rounded-xl bg-gradient-to-l from-logo-green-l to-logo-green-r">
        <div
          className={`${
            progressPercent < 100 ? progressBarWidth : "w-100p"
          } scale-xshadow-none flex space-between items-center whitespace-nowrap bg-gradient-to-l ${bgGradient(
            buttonMsg
          )} transition-all duration-500 ease-in-out`}
        ></div>
      </div>
      <div className="bottom-5 font-medium text-white absolute z-10 flex justify-between px-6 w-100p">
        <div className={`flex-shrink-0`}>{buttonMsg}</div>

        {progressPercent < 100 ? (
          <div className={`text-right flex-shrink-0`}>
            {progressPercent < 0
              ? "0 %"
              : `${Math.floor(100 - progressPercent).toString()} %`}
          </div>
        ) : null}
      </div>
      <img
        className={`absolute bottom-4 transform ${
          progressPercent < 100 ? metaMaskPosition : "translate-x-56"
        } transition-all duration-500 ease-in-out`}
        src={MetaMaskLogo}
        alt="metamask logo"
      />
    </div>
  );
}

function bgGradient(buttonMsg: string) {
  if (isNotErrorState(buttonMsg)) {
    return "from-mm-yellow to-mm-orange";
  } else {
    // error state
    return "from-red-400 to-red-600";
  }
}

function isNotErrorState(buttonMsg: string) {
  if (
    // If is loading states or normal state
    buttonMsg === "Connecting to MetaMask..." ||
    buttonMsg === "Authenticating address..." ||
    buttonMsg === "Signing message..." ||
    buttonMsg === "Authenticating signature..." ||
    buttonMsg === "Login with MetaMask" ||
    buttonMsg === "Success!" ||
    buttonMsg === "Click to download MetaMask"
  ) {
    return true;
  } else {
    return false;
  }
}

function openInNewTab() {
  const newWindow = window.open(
    "https://metamask.io/download.html",
    "_blank",
    "noopener,noreferrer"
  );
  if (newWindow) newWindow.opener = null;
}

/* DEVNOTE: Saved for reference!  
<button
className="h-16 w-72 py-3 px-6 bg-gradient-to-l from-mm-yellow to-mm-orange font-semibold rounded-xl cursor-pointer text-left transform hover:-translate-y-0.5 hover:shadow-loginButton transition duration-300 ease-loginButton focus:outline-none"
onClick={() => setIsLoading(true)}
>
<div className="flex items-center justify-between text-white">
  <div>Login with MetaMask</div>
  <img src={MetaMaskLogo} alt="metamask logo" />
</div>
</button> 
*/

const progressBarMappingWidth = {
  "100": "w-100p",
  "94.5": "w-94.5p",
  "83.5": "w-83.5p",
  "78": "w-78p",
  "72": "w-72p",
  "66.5": "w-66.5p",
  "61": "w-61p",
  "55.5": "w-55.5p",
  "50": "w-50p",
  "44.5": "w-44.5p",
  "39": "w-39p",
  "33.5": "w-33.5p",
  "27.5": "w-27.5p",
  "22": "w-22p",
  "16.5": "w-16.5p",
  "11": "w-11p",
  "5.5": "w-5.5p",
  "0": "w-0p",
};

const progressBarMappingTranslate = {
  "100": "translate-x-64",
  "94.5": "translate-x-64",
  "83.5": "translate-x-56",
  "78": "translate-x-52",
  "72": "translate-x-48",
  "66.5": "translate-x-44",
  "61": "translate-x-40",
  "55.5": "translate-x-36",
  "50": "translate-x-32",
  "44.5": "translate-x-28",
  "39": "translate-x-24",
  "33.5": "translate-x-20",
  "27.5": "translate-x-16",
  "22": "translate-x-12",
  "16.5": "translate-x-8",
  "11": "translate-x-4",
  "5.5": "translate-x-0",
  "0": "translate-x-0",
};

export default LoginButton;
