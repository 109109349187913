import React from "react";

function RequestsMain() {
  return (
    <div className="h-full w-full flex items-center justify-center">
      <div className="flex flex-col items-center m-8 text-black">
        <div className="pb-4 font-light text-3xl">Message Requests</div>
        <div className="pb-6 font-nrmal text-lg text-center leading-loose">
          These messages are from people you haven't accepted. <br /> They won’t
          know you’ve seen the request until you accept it and send them a
          message.
        </div>
      </div>
    </div>
  );
}

export default RequestsMain;
