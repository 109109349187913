import React from "react";
import Row from "./Row";

function Body({
  senderId,
  isRight,
  messages,
  nickname
}: {
  senderId: string;
  isRight: boolean;
  messages: any;
  nickname: string;
}) {
  return (
    <div className="message-body">
      {messages.map((message: any, index: number) => (
        <Row
          key={message.key}
          index={index}
          content={message.content}
          timeSent={message.timeSent}
          senderId={senderId}
          isRight={isRight}
          nickname={nickname}
        />
      ))}
    </div>
  );
}

export default Body;
