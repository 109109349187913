import React, { useEffect } from "react";
import Chat from "./Chat";
import { MobileViewType, useMobileViewStore } from "stores/useMobileViewStore";
import { SidebarType, useSidebarStore } from "stores/useSidebarStore";
import { useFocusedChatStore } from "stores/useFocusedChatStore";

function MainContent() {
  const activeSidebar = useSidebarStore((state) => state.activeSidebar);
  const mobileView = useMobileViewStore((state) => state.mobileView);
  const closeMain = useMobileViewStore((state) => state.closeMain);
  const focusedChannel = useFocusedChatStore((state) => state.focusedChannel);

  useEffect(() => {
    if (!focusedChannel && mobileView === MobileViewType.MAIN) {
      // in the case where we are in mobile mode and don't have a focused channel
      // never show the main content
      closeMain();
    }
  }, [focusedChannel, mobileView, closeMain]);

  function renderContent() {
    if (
      activeSidebar === SidebarType.CHATS ||
      activeSidebar === SidebarType.REQUESTS
    ) {
      return <Chat activeSidebar={activeSidebar} />;
    }
  }
  // absolute sm:static this is so that it is absolute on mobile and stati for mobile & above
  return (
    <div
      className={`main ${
        mobileView === MobileViewType.MAIN ? "main-visible" : ""
      }`}
      data-mobile-height=""
    >
      {renderContent()}
    </div>
  );
}

export default MainContent;
