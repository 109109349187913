import SendBird from "sendbird";

import ActionType from "./actionTypes";
import initialState from "./initialState";

import { useFocusedChatStore } from "stores/useFocusedChatStore";
import { useFriendsStore } from "stores/useFriendsStore";

type ReducerAction = {
  type: ActionType;
  payload: any;
};

export default function reducer(state: any, action: ReducerAction) {
  switch (action.type) {
    case ActionType.RESET_CHANNEL_LIST:
      return initialState;
    case ActionType.REFRESH_CHANNEL_LIST:
      const { allChannels: allChannelsT = [] } = state;
      const refreshedChannels = action.payload;
      for (let i = 0; i < allChannelsT.length; i++) {
        for (let j = 0; j < refreshedChannels.length; j++) {
          if (allChannelsT[i].url === refreshedChannels[j].url) {
            // found to replace
            allChannelsT.splice(i, 1, refreshedChannels[j]);
            break;
          }
        }
      }
      return {
        ...state,
        allChannels: allChannelsT,
      };
    case ActionType.INIT_CHANNELS_START:
      return {
        ...state,
        loading: true,
      };
    case ActionType.INIT_CHANNELS_SUCCESS:
      return {
        ...state,
        loading: false,
        allChannels: action.payload,
      };
    case ActionType.ON_USER_JOINED:
    case ActionType.ON_CHANNEL_CHANGED:
      useFriendsStore.getState().resetFriendsReal();

      const { allChannels = [] } = state;
      const { unreadMessageCount } = action.payload;
      const channel = action.payload;
      if (!channel.lastMessage) {
        return state;
      }
      // if its only an unread message count change, dont push to top
      if (unreadMessageCount === 0) {
        const currentChannel = allChannels.find(
          ({ url }: { url: string }) => url === channel.url
        );
        const currentUnReadCount =
          currentChannel && currentChannel.unreadMessageCount;
        if (currentUnReadCount === 0) {
          return {
            ...state,
            allChannels: allChannels.map((c: any) => {
              if (c.url === channel.url) {
                return channel;
              }
              return c;
            }),
          };
        }
      }
      return {
        ...state,
        allChannels: [
          action.payload,
          ...state.allChannels.filter(
            ({ url }: { url: string }) => url !== action.payload.url
          ),
        ],
      };
    case ActionType.CREATE_CHANNEL:
      useFocusedChatStore.getState().setFocusedChannel(action.payload);
      return {
        ...state,
        allChannels: [
          action.payload,
          ...state.allChannels.filter(
            (channel: any) => channel.url !== action.payload.url
          ),
        ],
      };

    case ActionType.ON_USER_LEFT: {
      const { channel, isMe } = action.payload;
      const { url } = channel;
      if (isMe) {
        const leftCurrentChannel = url === state.currentChannel;
        const newAllChannels = state.allChannels.filter(
          (c: SendBird.GroupChannel) => c.url !== url
        );
        const currentChannel = leftCurrentChannel
          ? (() => (newAllChannels.length > 0 ? newAllChannels[0].url : ""))()
          : state.currentChannel;

        return {
          ...state,
          currentChannel,
          allChannels: newAllChannels,
        };
      }
      // other user left
      const newAllChannels = state.allChannels.map((c: SendBird.GroupChannel) =>
        c.url === url ? channel : c
      );
      return {
        ...state,
        allChannels: newAllChannels,
      };
    }
    case ActionType.ON_CHANNEL_ARCHIVED:
    case ActionType.ON_CHANNEL_DELETED:
      const channelUrl = action.payload;

      // remove focus if the channel is removed
      const focusedChannel = useFocusedChatStore.getState().focusedChannel;
      if (focusedChannel?.url === channelUrl) {
        useFocusedChatStore.getState().setFocusedChannel(null);
      }

      const leftCurrentChannel = state.currentChannel === channelUrl;
      const newAllChannels = state.allChannels.filter(
        ({ url }: { url: string }) => url !== channelUrl
      );
      const currentChannel = leftCurrentChannel
        ? (() => (newAllChannels.length > 0 ? newAllChannels[0].url : ""))()
        : state.currentChannel;
      return {
        ...state,
        currentChannel,
        allChannels: newAllChannels,
      };
    case ActionType.ON_LAST_MESSAGE_UPDATED:
      return {
        ...state,
        allChannels: state.allChannels.map((channel: SendBird.GroupChannel) => {
          if (channel.url === action.payload.url) {
            return action.payload;
          }
          return channel;
        }),
      };
    case ActionType.ON_CHANNEL_FROZEN:
      return {
        ...state,
        allChannels: state.allChannels.map((channel: SendBird.GroupChannel) => {
          if (channel.url === action.payload.url) {
            channel.isFrozen = true;
            return channel;
          }
          return channel;
        }),
      };
    case ActionType.ON_CHANNEL_UNFROZEN:
      return {
        ...state,
        allChannels: state.allChannels.map((channel: SendBird.GroupChannel) => {
          if (channel.url === action.payload.url) {
            // eslint-disable-next-line no-param-reassign
            channel.isFrozen = false;
            return channel;
          }
          return channel;
        }),
      };
    case ActionType.ON_USER_BLOCKED:
      const blockedUserIds = new Set(action.payload);
      return {
        ...state,
        allChannels: state.allChannels.map((channel: SendBird.GroupChannel) => {
          const members = channel.members;
          for (let i = 0; i < members.length; i++) {
            if (blockedUserIds.has(members[i])) {
              channel.members[i].isBlockedByMe = true;
            }
          }
          return channel;
        }),
      };
    default:
      return state;
  }
}
