import { useProfileStore } from "stores/useProfileStore";
import { useAuthStore } from "stores/useAuthStore";
import { SYNC_USER_PROFILE, USER_PROFILES } from "../graphql/queries";
import { UserProfile } from "./types";

/**
 * refresh all users profiles that this current user has context to
 * - there isn't really a good reason to not just refresh all since it is only one backend query
 */

// TODO: refactor, this needs to be done, probably move into a Body/index.tsx hook
export async function refreshUserProfiles(userIds: string[]) {
  const { apolloClient } = useAuthStore.getState();

  const res = await apolloClient.query({
    query: USER_PROFILES,
    variables: {
      input: {
        walletAddresses: userIds,
      },
    },
  });

  let finalProfiles = new Map<string, UserProfile>();
  const profiles = res.data["userProfiles"]["userProfiles"];
  // build profiles into objects
  for (let i = 0; i < profiles.length; i++) {
    const profile = profiles[i];
    const userProfile = new UserProfile(
      profile.walletAddress,
      profile.contextSource
    );
    for (let i = 0; i < profile.userProfiles.length; i++) {
      const tempUserProfile = profile.userProfiles[i];
      userProfile.insertOrUpdateProfile(
        tempUserProfile.profileImageUrl,
        tempUserProfile.username,
        tempUserProfile.source
      );
    }
    finalProfiles.set(profile.walletAddress, userProfile);
  }

  // override existing profile store
  useProfileStore.getState().setNewProfiles(finalProfiles);
}

/**
 * given a url, sync and updates the context
 */
export async function syncUserProfile(originUrl: string) {
  const { apolloClient } = useAuthStore.getState();
  return new Promise((resolve, reject) => {
    apolloClient
      .query({
        query: SYNC_USER_PROFILE,
        variables: {
          input: {
            url: originUrl,
          },
        },
      })
      .then(() => {
        return resolve(true);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
