import React from "react";
import LoginButton from "components/Login/LoginButton";
import Title from "components/Login/Title";

import usePageLoad from "utils/hooks/usePageLoad";

function Login() {
  usePageLoad("Login");
  return (
    <div className="flex flex-col justify-center items-center w-full h-full">
      <Title />
      <LoginButton />
    </div>
  );
}

export default Login;
