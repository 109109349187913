import React, { useState, useEffect } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { isLoggedIn } from "../utils/auth";
import Loading from "../pages/Loading";

type PrivateRouteProps = {
  children: JSX.Element;
} & RouteProps;

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
// - if the users walletAddress is not set in memory, we check if they already have a cookie
//   and refresh if yes
export default function PrivateRoute({
  children,
  ...routeProps
}: PrivateRouteProps) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    isLoggedIn()
      .then((isAuth: boolean) => {
        setIsAuthenticated(isAuth);
        setLoading(false);
      })
      .catch(() => {
        // ignore the error, and try to login
        setLoading(false);
      });
  }, []);

  return (
    <Route
      {...routeProps}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : loading ? (
          <Loading />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
