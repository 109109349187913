import { Dispatch, useEffect } from "react";
import SendBird from "sendbird";
import { v4 as uuidv4 } from "uuid";
import compareIds from "utils/compareIds";
// @ts-ignore
import { useFrame } from 'react-frame-component';
import ActionType from "../dux/actionTypes";

import { scrollIntoLast } from "../utils";

function useHandleChannelEvents(
  sb: SendBird.SendBirdInstance,
  focusedChannel: SendBird.GroupChannel,
  messagesDispatcher: Dispatch<any>
) {
  const iframeDoc = useFrame().document;

  useEffect(() => {
    const messageReceiverId = uuidv4();

    const channelHandler = new sb.ChannelHandler();
    channelHandler.onMessageReceived = (channel, message) => {
      if (compareIds(channel.url, focusedChannel.url)) {
        messagesDispatcher({
          type: ActionType.ON_MESSAGE_RECEIVED,
          payload: { channel, message },
        });
        setTimeout(() => scrollIntoLast(undefined, iframeDoc));
      }
    };
    channelHandler.onMessageUpdated = (channel, message) => {
      messagesDispatcher({
        type: ActionType.ON_MESSAGE_UPDATED,
        payload: { channel, message },
      });
    };
    channelHandler.onMessageDeleted = (_, messageId) => {
      messagesDispatcher({
        type: ActionType.ON_MESSAGE_DELETED,
        payload: messageId,
      });
    };

    // missing from SendBird UI Kit
    // important because some updates the current state of an existing channel
    // - onReactionUpdated
    // - onChannelChanged
    // - onChannelFrozen
    // - onChannelUnFrozen
    // - onUserMuted
    // - onUserUnmuted
    // - onUserBanned
    // - onOperatorUpdated

    sb.addChannelHandler(messageReceiverId, channelHandler);
    return () => {
      sb.removeChannelHandler(messageReceiverId);
    };
  }, [sb, focusedChannel, messagesDispatcher, iframeDoc]);
}

export default useHandleChannelEvents;
