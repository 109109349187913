import { useEffect } from "react";
import SendBird from "sendbird";

import { refreshUserProfiles } from "utils/profile/action";
import { channelsGetMemberIdsWithoutMe } from "utils/chat/util";
import * as sentry from "utils/sentry";

/**
 * refresh user profiles whenever the member list changes
 */
function useUserProfiles(groupChannels: SendBird.GroupChannel[]) {
  const userIds = channelsGetMemberIdsWithoutMe(groupChannels);
  const sortedUserIds = Array.from(userIds).sort();

  // DEVNOTE: we need to use a strigified array because useEffect does not respect values within an object
  // https://github.com/facebook/react/issues/14476#issuecomment-471199055
  const stringifiedSortedUserIds = JSON.stringify(sortedUserIds);

  useEffect(() => {
    if (sortedUserIds.length) {
      refreshUserProfiles(sortedUserIds).catch((error) => {
        // silently ignore as it does not break main chat functionality
        sentry.captureSendBirdEx(
          "could not get user profiles",
          error,
          sentry.FATAL
        );
      });
    }
    // ignore lint warning from not specifying `sortedUserIds`. This is safe because
    // we need to stringify to not call useEffect unnecessarily
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedSortedUserIds]);
}

export default useUserProfiles;
