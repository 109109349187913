import Analytics from "@segment/analytics.js-core/build/analytics";

// @ts-ignore, no typescript
import SegmentIntegration from "@segment/analytics.js-integration-segmentio";

import * as config from "utils/config";

export function init() {
  // @ts-ignore
  const analytics = new Analytics();
  analytics.use(SegmentIntegration);
  const integrationSettings = {
    "Segment.io": {
      apiKey: config.REACT_APP_SEGMENT_WRITE_KEY,
      retryQueue: true,
      addBundledMetadata: true,

      // DEVNOTE: need this to enable cloud mode
      //
      // From Segment Email:
      //  The default behaviour of analytics.js-core is to assume that destinations are in device-mode.
      //  You will need to specify the integrations you want unbundled (aka. not loaded client-side) so
      //  that events are successfully sent through cloud-mode instead. You can do this by specifying the
      //  cloud-mode destinations you have set up by adding
      //  ` unbundledIntegrations: [ "INSERT_DESTINATION_HERE" ] within the integrationSettings object.
      unbundledIntegrations: ["Amplitude", "Google Analytics"],
    },
  };

  analytics.initialize(integrationSettings);

  // @ts-ignore
  window.analytics = analytics;
}
