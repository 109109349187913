import React from "react";
import Sidebar from "components/Sidebar";
import MainContent from "components/MainContent";

function ChatsLayout() {
  return (
    <div className="layout">
      <Sidebar />
      <MainContent />
    </div>
  );
}

export default ChatsLayout;
