import {
  ApolloClient,
  DefaultOptions,
  InMemoryCache,
  HttpLink,
} from "@apollo/client";
import * as config from "../config";

// set default to no cache, there are currently only login auth apis which should never
// be cached
const defaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: "no-cache",
  },
};

const httpLink = new HttpLink({
  uri: config.REACT_APP_GRAPHQL_API_URL,
  credentials: "include",
});

export function createApolloClient() {
  return new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
  });
}
