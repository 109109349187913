import React from "react";

function ChatPhoto({
  children,
  isUser,
}: {
  children: React.ReactNode;
  isUser: boolean;
}) {
  return (
    <div className={`${isUser ? "truncate" : "col-6 col-xl-6"}`}>
      <div className="media text-center text-xl-left">{children}</div>
    </div>
  );
}

export default ChatPhoto;
