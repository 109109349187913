import create, { SetState, GetState } from "zustand";
import { ApolloClient } from "@apollo/client";
import { createApolloClient } from "../utils/graphql/client";
import { useChatStore } from "./useChatStore";
import { useProfileStore } from "./useProfileStore";
import { useFocusedChatStore } from "./useFocusedChatStore";
import {
  login as loginHelper,
  logout as logoutHelper,
  refresh as refreshHelper,
} from "../utils/auth";
import { userIdentify } from "../utils/segment/track";
import { UserStatus } from "../utils/chat/types";
import * as sentry from "../utils/sentry";

type AuthStore = {
  apolloClient: ApolloClient<any>;

  login: (changeButton: (msg: string, progress: number) => void) => void;
  logout: (cb: () => void) => void;
  refresh: (cb: () => void) => void;
};

export const useAuthStore = create<AuthStore>(
  (set: SetState<AuthStore>, get: GetState<AuthStore>) => ({
    apolloClient: createApolloClient(),

    login: async (changeButton) => {
      const walletAddressInner = await loginHelper(changeButton);
      await useChatStore.getState().connect(walletAddressInner);

      userIdentify();

      useProfileStore.getState().setCurrentUser();
      useProfileStore
        .getState()
        .setCurrentUserStatusWithValue(UserStatus.ONLINE);
      sentry.setUser(walletAddressInner);
    },

    logout: async (cb: () => void) => {
      await logoutHelper();
      // NTF: this works for now, but probably better
      // to error if sendbird disconnect fails
      // - a lot of the errors have been because of a `Chat` related component requiring SendBird
      //   should probably add a global state somewhere to logout
      if (typeof cb !== "undefined") {
        cb();
      }
      await useChatStore.getState().disconnect();
      useProfileStore.getState().resetCurrentUser();
      useFocusedChatStore.getState().setFocusedChannel(null);
      sentry.setUser(null);
    },

    refresh: async (cb: () => void) => {
      const walletAddressInner = await refreshHelper();
      await useChatStore.getState().connect(walletAddressInner);

      userIdentify();
      useProfileStore.getState().setCurrentUser();
      useProfileStore
        .getState()
        .setCurrentUserStatusWithValue(UserStatus.ONLINE);
      sentry.setUser(walletAddressInner);
      if (typeof cb !== "undefined") {
        cb();
      }
    },
  })
);
