import SendBird from "sendbird";

import { getMyUserId } from "utils/chat/util";
import { getFullContext } from "./util";

const OBJECT_MESSAGE = "Message";
const OBJECT_CHANNEL = "Channel";

const ACTION_CREATED = "Created";
const ACTION_SENT = "Sent";

function buildEvent(obj: string, action: string) {
  return `${obj} ${action}`;
}

/**
 * DEVNOTE: we specify a page name because in chrome extension button and app usage, segment
 * takes the url, eg if the user is on opensea.io/accounts/Metakovan, it will use specify
 * accounts/Metakovan as the path
 */
export function page(pageName: string) {
  // @ts-ignore
  window.analytics.page(pageName, getFullContext());
}

export function userIdentify() {
  // @ts-ignore
  window.analytics.identify(getMyUserId(), getFullContext());
}

export function createConversation(channel: SendBird.GroupChannel) {
  // @ts-ignore
  window.analytics.track(buildEvent(OBJECT_CHANNEL, ACTION_CREATED), {
    channel_url: channel.url,
    channel_details: channel,
    ctx: getFullContext(),
  });
}

export function messageSent(
  message: SendBird.UserMessage | SendBird.FileMessage | SendBird.AdminMessage
) {
  // @ts-ignore
  window.analytics.track(buildEvent(OBJECT_MESSAGE, ACTION_SENT), {
    message_id: message.messageId,
    message_details: message,
    ctx: getFullContext(),
  });
}

export function reset() {
  // @ts-ignore
  window.analytics.reset();
}
