import { getProvider } from "./eth";
import { useAuthStore } from "../stores/useAuthStore";
import {
  GET_AUTH_CHALLENGE,
  AUTH_LOGIN,
  REFRESH_AUTH_LOGIN,
  AUTH_LOGOUT,
} from "./graphql/queries";
import { setAccessToken } from "./accessToken";
import SendBird from "sendbird";
import * as sentry from "../utils/sentry";
import cryptojs from "crypto-js";

export async function login(
  changeButton: (msg: string, progress: number) => void
) {
  const { apolloClient } = useAuthStore.getState();

  let provider;
  let signer: any;
  try {
    changeButton("Connecting to MetaMask...", 83.5);
    provider = await getProvider();
    signer = provider.getSigner();
  } catch (error) {
    if (error.message === "Click to download MetaMask") {
      throw new Error(error.message);
    } else {
      throw new Error("Failed to connect to MetaMask.");
    }
  }

  let walletAddress, loginChallenge, nonce;
  try {
    walletAddress = await signer.getAddress();
    changeButton("Authenticating address...", 66.5);
    loginChallenge = await apolloClient.query({
      query: GET_AUTH_CHALLENGE,
      variables: {
        input: {
          walletAddress,
        },
      },
    });
    nonce = loginChallenge["data"]["getAuthChallenge"]["nonce"];
  } catch (error) {
    // failed to get nonce from backend
    // IMO this should be hidden from the user and we should throw a generic error message
    throw new Error("Failed to authenticate address.");
  }

  let unsignedMessage, signedMessage;
  try {
    unsignedMessage = buildWalletSignMessage(nonce, walletAddress);
    changeButton("Signing message...", 50);
    signedMessage = await signer.signMessage(unsignedMessage);
  } catch (error) {
    // request for user to sign our msg with their wallet
    throw new Error("Failed to sign message.");
  }

  let loginWallet, token;
  try {
    changeButton("Authenticating signature...", 22);
    loginWallet = await apolloClient.mutate({
      mutation: AUTH_LOGIN,
      variables: {
        input: {
          walletAddress: walletAddress,
          unsignedMessage: unsignedMessage,
          signedMessage: signedMessage,
        },
      },
    });
    token = loginWallet["data"]["authLogin"]["token"];
    setAccessToken(decryptToken(token, walletAddress));
  } catch (error) {
    // backend failed to validate their signature
    throw new Error("Failed to authenticate signature");
  }

  return walletAddress;
}

export async function logout() {
  const { apolloClient } = useAuthStore.getState();

  const logoutRes = await apolloClient.mutate({
    mutation: AUTH_LOGOUT,
  });
  setAccessToken("");
  return logoutRes["data"]["authLogout"]["logout"];
}

export async function refresh() {
  const { apolloClient } = useAuthStore.getState();

  const refreshRes = await apolloClient.query({
    query: REFRESH_AUTH_LOGIN,
  });
  const walletAddress = refreshRes["data"]["refreshAuthLogin"]["walletAddress"];
  const token = refreshRes["data"]["refreshAuthLogin"]["token"];
  setAccessToken(decryptToken(token, walletAddress));
  return walletAddress;
}

export async function isLoggedIn() {
  // get the state of the current sendbird connection
  const sb = SendBird.getInstance();
  if (!sb) {
    sentry.captureSendBirdMsg(
      "could not find sendbird instance when logging in",
      sentry.FATAL
    );
    return false;
  }

  const state = sb.getConnectionState();
  if (state === "OPEN") {
    // user is connected and stable, return true
    return true;
  }

  try {
    const refreshHelper = useAuthStore.getState().refresh;
    await refreshHelper(function () {});
    return true;
  } catch (err) {
    return false;
  }

  // this should never happen
}

function buildWalletSignMessage(nonce: number, walletAddress: string) {
  return `Welcome to TokiTalk!

    Click "Sign" to sign in. No password needed!

    I accept the TokiTalk Terms of Service.

    Wallet address:
    ${walletAddress}
    Nonce:
    ${nonce}
  `;
}

/**
 * this is just to make it annoying for hackers
 * - should match client side code :O
 */
function generateSecret(walletAddress: string) {
  const today = new Date();
  const todayWithoutTime = new Date(
    today.getUTCFullYear(),
    today.getUTCMonth(),
    today.getUTCDate(),
    today.getUTCHours()
  )
    .getTime()
    .toString();
  const randomNumberFromDate = todayWithoutTime[todayWithoutTime.length - 4];
  let secret = "";
  const randomNumberFromWallet = walletAddress[4].charCodeAt(0);
  for (let i = walletAddress.length - 1; i >= 0; i--) {
    if (i % 3 === 0) {
      secret += walletAddress[i].charCodeAt(0) + randomNumberFromDate;
    }
    if (i % 2 === 0) {
      secret += String.fromCharCode(
        walletAddress[i].charCodeAt(0) + randomNumberFromWallet
      );
    }
  }
  return (
    secret +
    "2" +
    walletAddress[4] +
    "1" +
    walletAddress[walletAddress.length - 2]
  );
}

export function decryptToken(token: string, walletAddress: string) {
  const secret = generateSecret(walletAddress);
  let decryptedToken = cryptojs.AES.decrypt(token, secret).toString(
    cryptojs.enc.Utf8
  );
  return decryptedToken.substring(1, decryptedToken.length - 1);
}
