import React from "react";
import WalletAddress from "components/WalletAddress";

function Content({
  index,
  content,
  timeSent,
  isRight,
  senderId,
  nickname,
}: {
  index: number;
  content: string;
  timeSent: Date;
  isRight: boolean;
  senderId: string;
  nickname: string;
}) {
  // choose date format based on if more or less than 24 hrs ago
  const options =
    new Date().getTime() - timeSent.getTime() > 86400000
      ? {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }
      : { hour: "numeric", minute: "numeric", hour12: true };

  // @ts-ignore
  const time = timeSent.toLocaleString("en-US", options);
  return (
    <div
      className={`message-content ${
        isRight ? "bg-primary text-white" : "bg-light"
      }`}
    >
      {/* break-all fixes the chat overflow issue*/}
      {index === 0 && !isRight ? <h6 className="mb-2 break-all">{nickname ? nickname : <WalletAddress walletAddress={senderId}/>}</h6> : null}
      <div>{content}</div>

      <div className="mt-1">
        <small className="opacity-65">{time}</small>
      </div>
    </div>
  );
}

export default Content;
