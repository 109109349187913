import { ethers } from "ethers";

/**
 * requests the provider from the current window
 * - will also request for access
 * - will fail if no ethereum object installed
 *
 */
export async function getProvider() {
  // @ts-ignore
  if (!window.ethereum) {
    throw new Error("Click to download MetaMask");
  }
  // DEVNOTE: this is an no-op if access is already granted
  // @ts-ignore
  await window.ethereum.request({ method: "eth_requestAccounts" });
  // @ts-ignore
  return new ethers.providers.Web3Provider(window.ethereum);
}

export async function isValidWallet(walletAddress: string) {
  try {
    const provider = await getProvider();
    // DEVNOTE: the getCode function will throw an error if the checksum is not correct
    const code = await provider.getCode(walletAddress);
    if (code !== "0x") {
      throw new Error(`unexpected wallet code, actual: ${code}, expected: 0x`);
    }
  } catch (err) {
    console.log(err);
    throw new Error("given wallet address is not valid");
  }
}

/**
 * Truncates wallet address so that it's 0xXX...XXXX
 * @param str
 * @returns str
 */
export function truncate(str: string, leftSide = 4, rightSide = 4) {
  return (
    str.substr(0, leftSide) +
    "…" +
    str.substr(str.length - rightSide, str.length)
  );
}
