import React from "react";
import Logo from "../Logo";

function Title() {
  return (
    <div className="flex flex-col mb-8 items-center justify-center">
      <Logo CSSClasses="w-16" />
      <div className="mt-3 text-3xl font-semibold text-gray-900">
        Welcome to TokiTalk
      </div>
      <div className="mt-1 text-center text-sm text-gray-600">
        Connect with the world with crypto.
      </div>
    </div>
  );
}

export default Title;
