import React, { useState, useRef, useEffect, useCallback } from "react";
import { utils } from "ethers";

import InputButtonGroup from "./InputButtonGroup";
import WalletIcon from "img/wallet-icon.png";

import { useProfileStore } from "stores/useProfileStore";
import { useFocusedChatStore } from "stores/useFocusedChatStore";
import { useMobileViewStore } from "stores/useMobileViewStore";
import useNewChatStore from "stores/useNewChatStore";

import { createConversation } from "utils/chat/action";
import { syncUserProfile } from "utils/profile/action";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { getSourceFromUrl } from "utils/profile/source";
import * as config from "utils/config";
import * as sentry from "utils/sentry";

function AddChatModal({
  closeAddChatModal,
  isAddChatModalOpen,
}: {
  closeAddChatModal: () => void;
  isAddChatModalOpen: boolean;
}) {
  const setFocusedChannel = useFocusedChatStore(
    (state) => state.setFocusedChannel
  );
  const [walletAddress, setWalletAddress] = useState("");
  const [isAddingChat, setIsAddingChat] = useState(false);
  const [error, setError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const modalRef = useRef(null);
  const setCachedProfileWithMetadata = useProfileStore(
    (state: any) => state.setCachedProfileWithMetadata
  );
  const openMain = useMobileViewStore((state) => state.openMain);

  const {
    username,
    profilePicUrl,
    walletAddress: newWalletAddress,
    originUrl,
    setNewChat,
  } = useNewChatStore();

  const closeModal = useCallback(() => {
    setNewChat("", "", "", "");
    closeAddChatModal();
  }, [closeAddChatModal, setNewChat]);

  useOutsideClick(modalRef, closeModal);

  const handleCreateConversation = useCallback(async () => {
    setIsAddingChat(true);
    try {
      createConversation(
        utils.getAddress(newWalletAddress ? newWalletAddress : walletAddress)
      ).then(([groupChannel]: any) => {
        // cache the profile if all params exists
        if (newWalletAddress && originUrl && profilePicUrl) {
          setCachedProfileWithMetadata(
            newWalletAddress,
            getSourceFromUrl(originUrl),
            profilePicUrl,
            username
          );
        }
        setIsSuccess(true);
        setTimeout(() => {
          setIsSuccess(false);
          setFocusedChannel(groupChannel);
          openMain();
          setIsAddingChat(false);
          setWalletAddress("");
          closeModal();
        }, 750);
      });
    } catch (err) {
      // @sean, error
      console.log(err);
      setIsAddingChat(false);
      setError(err);
      setTimeout(() => {
        setError("");
      }, 3000);
    }
    try {
      if (newWalletAddress && originUrl && profilePicUrl) {
        await syncUserProfile(originUrl);
      }
    } catch (err) {
      console.log(err);
      // silently error here because it does not break main functionality
      sentry.captureGenericMsgEx(
        "Could not sync user profile",
        err,
        sentry.WARN
      );
    }
  }, [
    closeModal,
    newWalletAddress,
    openMain,
    originUrl,
    profilePicUrl,
    setCachedProfileWithMetadata,
    setFocusedChannel,
    username,
    walletAddress,
  ]);

  useEffect(() => {
    if (newWalletAddress && originUrl && profilePicUrl) {
      handleCreateConversation();
    }
  }, [handleCreateConversation, newWalletAddress, originUrl, profilePicUrl]);

  function renderImage() {
    if (profilePicUrl && isValidHttpUrl(profilePicUrl)) {
      return (
        <div className="avatar avatar-xl mr-6">
          <img
            className="avatar-img object-cover"
            src={profilePicUrl}
            alt="External Avatar"
          />
        </div>
      );
    } else if (profilePicUrl) {
      // Handle Foundation.app default pictures (they are linear-gradients)
      return (
        <div
          className="avatar avatar-xl mr-6"
          style={{
            background: profilePicUrl,
          }}
        ></div>
      );
    } else {
      return <img className="mr-6 w-12" src={WalletIcon} alt="wallet-icon" />;
    }
  }

  function renderTitle() {
    if (username || newWalletAddress) {
      // when navigating from an injected value, username is optional, display walletAddress
      // if we did not manage to find the walletAddress
      return (
        <h1 className="modal-title overflow-hidden truncate">
          {username || newWalletAddress}
        </h1>
      );
    } else {
      return <h5 className="modal-title">Start a new conversation</h5>;
    }
  }

  function renderSubtitle() {
    if (originUrl) {
      const externalDomain = new URL(originUrl);
      return (
        <p>
          {`from `}
          <a href={originUrl} target="_blank" rel="noreferrer">
            {externalDomain.hostname.replace("www.", "")}
          </a>
        </p>
      );
    } else {
      return <p className="small">Enter a wallet address to start chatting!</p>;
    }
  }

  return (
    <div
      className={`modal fade ${isAddChatModalOpen ? "show" : ""}`}
      id="invite-friends"
      tabIndex={-1}
      role="dialog"
      aria-hidden={isAddChatModalOpen ? "false" : "true"}
      aria-modal={isAddChatModalOpen ? "true" : "false"}
      style={isAddChatModalOpen ? { display: "block" } : {}}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content" ref={modalRef}>
          <div className="modal-header truncate">
            <div className="media flex-fill truncate">
              {renderImage()}
              <div className="media-body align-self-center truncate">
                {renderTitle()}
                {renderSubtitle()}
              </div>
            </div>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <label htmlFor="invite-wallet" className="small">
              Wallet Address
            </label>
            <div className="input-group">
              <input
                type="text"
                className="form-control form-control-lg"
                id="invite-email"
                value={newWalletAddress ? newWalletAddress : walletAddress}
                disabled={newWalletAddress ? true : false}
                onChange={(e: any) => setWalletAddress(e.target.value)}
              />
              {newWalletAddress || config.REACT_APP_IS_EXTENSION ? null : (
                <InputButtonGroup />
              )}
            </div>
            {newWalletAddress || walletAddress ? null : ( // @sean TODO: change webflow link
              <p className="text-xs mt-3">
                Can't find an address?{" "}
                <a
                  href="https://www.tokitalk.com/integrations"
                  target="_blank"
                  rel="noreferrer"
                >
                  Find our guide
                </a>{" "}
                or{" "}
                <a
                  href="#/"
                  onClick={(e) => {
                    e.preventDefault();
                    setWalletAddress(
                      "0x932afb6DD9433F0fF4A65EF54de16418dD69e8E5"
                    );
                  }}
                >
                  talk to the founders!
                </a>
              </p>
            )}
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className={`${
                isSuccess
                  ? "bg-gradient-to-r from-logo-green-l to-logo-green-r focus:outline-none outline-none text-white"
                  : isAddingChat
                  ? "bg-gradient-to-r from-btn-green via-btn-teal via-btn-cyan to-btn-blue animate-gradient focus:outline-none outline-none text-white"
                  : error
                  ? "btn-danger"
                  : "btn-primary"
              } btn btn-lg btn-block d-flex align-items-center`}
              style={{ border: "none", backgroundSize: "400% 400%" }}
              onClick={handleCreateConversation}
            >
              {isSuccess
                ? "Success!"
                : isAddingChat
                ? "Loading conversation..."
                : error
                ? `${error}`
                : "Start Conversation"}
              <i
                className={`${
                  isSuccess
                    ? "fe-check-circle"
                    : isAddingChat
                    ? "fe-loader animate-spin"
                    : error
                    ? "fe-alert-triangle"
                    : "fe-send"
                } text-xl ml-auto`}
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function isValidHttpUrl(string: string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export default AddChatModal;
