import React, { useState, useEffect } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { isLoggedIn } from "utils/auth";
import Loading from "pages/Loading";

type LoginRouteProps = {
  children: JSX.Element;
} & RouteProps;

// A wrapper for <Route> that redirects to the home page if the user is
// already authenticated
export default function LoginRoute({
  children,
  ...routeProps
}: LoginRouteProps) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    isLoggedIn()
      .then((isAuth: boolean) => {
        setIsAuthenticated(isAuth);
        setLoading(false);
      })
      .catch(() => {
        // ignore the error, and try to login
        setLoading(false);
      });
  }, []);

  return (
    <Route
      {...routeProps}
      render={({ location }) =>
        loading ? (
          <Loading />
        ) : !isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
