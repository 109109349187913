import React from "react";
import { truncate } from "utils/eth";

function NoMessage({
  nickname,
  walletAddress,
}: {
  nickname: string;
  walletAddress: string;
}) {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="rounded-md bg-blue-50 p-4 shadow-md">
        <div className="flex">
          <div className="flex-shrink-0">
            <i
              className="fe-message-square h-5 w-5 text-blue-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">{`Type your message below to start chatting with ${
              nickname ? nickname : truncate(walletAddress)
            }!`}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoMessage;
