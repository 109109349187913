import * as config from "utils/config";

const CTX_WEB = "Web";
const CTX_EXT_CS = "Chrome Extension Content Script";
const CTX_EXT_CS_OPENSEA = "Chrome Extension Content Script Opensea";
const CTX_EXT_CS_SHOWTIME = "Chrome Extension Content Script Showtime";
const CTX_EXT_WIN = "Chrome Extension Window";

/**
 * returns the current context
 */
function getSource() {
  if (!config.REACT_APP_IS_EXTENSION) {
    return CTX_WEB;
  }
  if (window.location.href.includes("chrome-extension://")) {
    // window chrome extension
    return CTX_EXT_WIN;
  } else if (window.location.href.includes("opensea.io")) {
    return CTX_EXT_CS_OPENSEA;
  } else if (window.location.href.includes("tryshowtime.com")) {
    return CTX_EXT_CS_SHOWTIME;
  }
  return CTX_EXT_CS;
}

export function getFullContext() {
  return {
    from_src: getSource(),
    from_domain: window.location.origin,
  };
}
