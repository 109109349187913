import React, { useState, useEffect } from "react";
import { truncate } from "utils/eth";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
// @ts-ignore
import { useFrame } from "react-frame-component";
import { REACT_APP_IS_EXTENSION } from "utils/config";

function WalletAddress({ walletAddress }: { walletAddress: string }) {
  const [isHover, setIsHover] = useState(false);
  const [isSuccessCopy, setIsSuccessCopy] = useState<boolean | null>(null);
  const { document } = useFrame();

  useEffect(() => {
    if (isSuccessCopy === true || isSuccessCopy === false) {
      setTimeout(() => {
        setIsSuccessCopy(null);
      }, 3000);
    }
  }, [isSuccessCopy]);

  return (
    <Tippy
      theme={
        isSuccessCopy === null ? "normal" : isSuccessCopy ? "success" : "fail"
      }
      appendTo={document.body}
      visible={isHover}
      placement={REACT_APP_IS_EXTENSION ? "top" : "right"}
      className={`py-1.5 px-2 rounded-lg font-medium ${
        isSuccessCopy === null ? "bg-blue-100 text-blue-800" : ""
      }`}
      content={
        isSuccessCopy === null
          ? "Copy 📋"
          : isSuccessCopy
          ? "Copied 👍"
          : "Failed 👎"
      }
    >
      <span
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          copyTextToClipboard(walletAddress, setIsSuccessCopy);
        }}
        className={`max-w-full inline-flex items-center ${
          isSuccessCopy
            ? "text-green-800"
            : isSuccessCopy === false
            ? "text-red-800"
            : isHover
            ? "text-blue-800"
            : ""
        } cursor-pointer`}
      >
        <span
          className="break-all truncate"
          style={{
            whiteSpace: "inherit",
          }}
        >
          {truncate(walletAddress)}
        </span>
      </span>
    </Tippy>
  );
}

function copyTextToClipboard(text: string, cb: (isSuccess: boolean) => void) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text, cb);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      cb(true);
    },
    function (err) {
      cb(false);
    }
  );
}

function fallbackCopyTextToClipboard(
  text: string,
  cb: (isSuccess: boolean) => void
) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    cb(successful);
  } catch (err) {
    cb(false);
  }

  document.body.removeChild(textArea);
}

export default WalletAddress;
