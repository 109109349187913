import React from "react";

function UploadImage({
  setImage,
}: {
  setImage: React.Dispatch<React.SetStateAction<File | undefined>>;
}) {
  return (
    <div className="form-group">
      <label className="small">Avatar</label>
      <div
        onDragOver={preventDefault}
        onDragEnter={preventDefault}
        onDragLeave={preventDefault}
        onDrop={(e) => {
          e.preventDefault();
          const fileList = e.dataTransfer.files;
          if(!fileList && !validateFile(fileList[0])) return;
          setImage(fileList[0])
        }}
        className="position-relative text-center bg-secondary rounded p-6"
      >
        <div className="avatar bg-primary text-white mb-5">
          <i className="icon-md fe-image"></i>
        </div>

        <p className="small text-muted mb-0">
          You can upload jpg, gif or png files. <br /> Max file size 3mb.
        </p>
        <input
          id="upload-user-photo"
          className="d-none"
          type="file"
          accept="image/*"
          onChange={(e) => {
            const fileList = e.target.files;
            if (!fileList) return;
            setImage(fileList[0]);
          }}
        />
        <label
          className="stretched-label mb-0"
          htmlFor="upload-user-photo"
        ></label>
      </div>
    </div>
  );
}

function preventDefault(e: any) {
  e.preventDefault()
}

function validateFile(file: any) {
  const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/x-icon'];
  if (validTypes.indexOf(file.type) === -1) {
      return false;
  }
  return true;
}

export default UploadImage;
