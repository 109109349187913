import React, { useRef, useState, useReducer } from "react";
import SendBird from "sendbird";

import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";
import ChatBody from "../../components/ChatBody";

import messagesInitialState from "./dux/initialState";
import messagesReducers from "./dux/reducers";
import useInitialMessagesFetch from "./hooks/useInitialMessagesFetch";
import useHandleChannelEvents from "./hooks/useHandleChannelEvents";
import useSendMessageCallback from "./hooks/useSendMessageCallback";
import useScrollCallback from "./hooks/useScrollCallback";

import { formatMessageWrappers } from "utils/chat/util";
import {
  getOtherMember,
  getUserState,
  getUserProfileUrl,
} from "utils/chat/util";
import useProfileContext from "utils/hooks/useProfileContext";

function Body({ focusedChannel }: { focusedChannel: SendBird.GroupChannel }) {
  const sb = SendBird.getInstance();
  const [alert, setAlert] = useState({ type: "", text: "" });

  const [messagesStore, messagesDispatcher] = useReducer(
    messagesReducers,
    messagesInitialState
  );
  const { allMessages, loading, lastMessageTimeStamp } = messagesStore;

  const sendUserMessageCallback = useSendMessageCallback(
    sb,
    focusedChannel,
    messagesDispatcher
  );
  useInitialMessagesFetch(sb, focusedChannel, messagesDispatcher);
  useHandleChannelEvents(sb, focusedChannel, messagesDispatcher);
  const scrollCallback = useScrollCallback(
    focusedChannel,
    messagesDispatcher,
    lastMessageTimeStamp
  );

  const formattedMessages = formatMessageWrappers(focusedChannel, allMessages);

  const scrollRef = useRef<HTMLDivElement>(null);
  const otherMember = getOtherMember(focusedChannel);

  const name = otherMember.nickname;
  const walletAddress = otherMember.userId;
  const profileUrl = getUserProfileUrl(otherMember);

  const { displayName, displayProfileUrl } = useProfileContext(
    walletAddress,
    name,
    profileUrl
  );

  return (
    <ChatBody>
      <Header
        name={displayName}
        walletAddress={walletAddress}
        status={getUserState(otherMember)}
        imgSrc={displayProfileUrl}
        isUser={false}
      />
      {loading ? (
        <div className="h-100 w-100 flex flex-col justify-center items-center">
          <i className="fe-loader animate-spin text-4xl bg-gradient-to-r from-logo-green-l to-logo-green-r text-transparent bg-clip-text"></i>
        </div>
      ) : (
        <Content
          messageWrappers={formattedMessages}
          alert={alert}
          closeAlert={() => setAlert({ type: "", text: "" })}
          onScroll={scrollCallback}
          scrollRef={scrollRef}
          focusedChannel={focusedChannel}
          walletAddress={walletAddress}
          nickname={displayName}
        />
      )}
      <Footer
        focusedChannel={focusedChannel}
        setAlert={setAlert}
        sendUserMessage={sendUserMessageCallback}
      />
    </ChatBody>
  );
}

export default Body;
