import React from "react";

function MessageRequestButton({
  openRequests,
  resetFocusedChannel,
  numUnreadMsgs,
}: {
  openRequests: () => void;
  resetFocusedChannel: () => void;
  numUnreadMsgs: number;
}) {
  const isOverflow: boolean = numUnreadMsgs > 99;

  function setSize() {
    let size: string | undefined;
    const len = Number(numUnreadMsgs).toString().length;
    if (len === 1) {
      size = "1rem";
    } else if (len === 2) {
      size = "1.3rem";
    }

    if (size) {
      return {
        width: size,
        height: size,
        minWidth: size,
      };
    }
  }

  return (
    <button
      type="button"
      className="btn btn-lg btn-block btn-secondary d-flex align-items-center mb-6"
      data-toggle="modal"
      data-target="#invite-friends"
      onClick={() => {
        openRequests();
        resetFocusedChannel();
      }}
    >
      <div>Message Requests</div>
      {numUnreadMsgs ? (
        <>
          <span
            style={setSize()}
            className="relative inline-flex badge badge-circle badge-primary ml-auto"
          >
            <span
              style={{
                transform: "none",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                background: "#07b53b",
              }}
              className="animate-ping-slow absolute inline-flex h-full w-full rounded-full opacity-75"
            ></span>
            <span>{isOverflow ? "99+" : numUnreadMsgs}</span>
          </span>
        </>
      ) : null}
    </button>
  );
}


export default MessageRequestButton;
