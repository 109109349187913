import { Dispatch, useEffect } from "react";
import SendBird, { SendBirdInstance } from "sendbird";

import { genMessageListQuery } from "utils/chat/action";
import * as sentry from "utils/sentry";
import { useNotificationsStore } from "stores/useNotificationsStore";
// @ts-ignore
import { useFrame } from "react-frame-component";

import ActionType from "../dux/actionTypes";
import { scrollIntoLast } from "../utils";

const NEXT_RESULT_SIZE = 10;

// NTF: handle type
const getLatestMessageTimeStamp = (messages: any = []) => {
  const latestMessage = messages[messages.length - 1];
  return (latestMessage && latestMessage.createdAt) || null;
};

function useInitialMessagesFetch(
  sb: SendBirdInstance,
  focusedChannel: SendBird.GroupChannel,
  messagesDispatcher: Dispatch<any>
) {
  const iframeDoc = useFrame().document;
  const addNewErrorNotification = useNotificationsStore(
    (state) => state.addNewErrorNotification
  );

  useEffect(() => {
    const initialTimestamp = new Date().getTime();

    // first reset all existing messages as we are about to clear everything
    messagesDispatcher({
      type: ActionType.RESET_MESSAGES,
    });

    const messageListQuery = genMessageListQuery();

    messagesDispatcher({
      type: ActionType.GET_PREV_MESSAGES_START,
    });

    // get messages from initialTimestamp
    messageListQuery.nextResultSize = NEXT_RESULT_SIZE;

    focusedChannel
      .getMessagesByTimestamp(initialTimestamp, messageListQuery)
      .then((messages) => {
        const hasMore = messages && messages.length > 0;
        const lastMessageTimeStamp = hasMore ? messages[0].createdAt : null;
        const latestFetchedMessageTimeStamp = getLatestMessageTimeStamp(
          messages
        );

        const nextMessageListParams = new sb.MessageListParams();
        nextMessageListParams.nextResultSize = NEXT_RESULT_SIZE;

        messagesDispatcher({
          type: ActionType.GET_PREV_MESSAGES_SUCCESS,
          payload: {
            messages,
            hasMore,
            lastMessageTimeStamp,
            focusedChannel,
            latestFetchedMessageTimeStamp,
          },
        });
      })
      .catch((error) => {
        addNewErrorNotification("Could not load messages");
        sentry.captureSendBirdEx(
          "Could not load messages",
          error,
          sentry.FATAL
        );
      })
      .finally(() => {
        setTimeout(() => scrollIntoLast("auto", iframeDoc));
        focusedChannel.markAsRead();
      });
  }, [
    sb,
    focusedChannel,
    messagesDispatcher,
    iframeDoc,
    addNewErrorNotification,
  ]);
}

export default useInitialMessagesFetch;
