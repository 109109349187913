import React from "react";
import { ModalType, useModalStore } from "stores/useModalStore";
import { SidebarType } from "stores/useSidebarStore";

function ChatsTitle({
  activeSidebar,
  openSidebarChats,
  resetFocusedChannel,
}: {
  activeSidebar: SidebarType;
  openSidebarChats: () => void;
  resetFocusedChannel: () => void;
}) {
  const setModal = useModalStore((state) => state.setModal);

  if (activeSidebar === SidebarType.CHATS) {
    return (
      <div className="flex mb-6 items-center">
        <h2 className="font-bold">Chats</h2>
        <button
          type="button"
          className="btn-success btn-ico btn-sm rounded-circle ml-auto focus:outline-none"
          onClick={() => setModal(ModalType.ADD_CHAT)}
        >
          <i className="fe-edit"></i>
        </button>
      </div>
    );
  } else if (activeSidebar === SidebarType.REQUESTS) {
    return (
      <h2 className="font-bold mb-6">
        <i
          className="fe-arrow-left mr-6 cursor-pointer"
          onClick={() => {
            openSidebarChats();
            resetFocusedChannel();
          }}
        ></i>
        <span>Message Requests</span>
      </h2>
    );
  } else {
    return null;
  }
}

export default ChatsTitle;
