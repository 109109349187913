import React, { useState } from "react";
import Message from "./Message";
import Alert from "./Alert";
import NoMessage from "./NoMessage";

import { MessageFormattedWrapper } from "utils/chat/types";
import * as sentry from "utils/sentry";

function Content({
  messageWrappers,
  alert,
  closeAlert,
  scrollRef,
  onScroll,
  focusedChannel,
  walletAddress,
  nickname,
}: {
  messageWrappers: MessageFormattedWrapper[] | undefined;
  alert: { text: string; type: string };
  closeAlert: () => void;
  scrollRef: React.RefObject<HTMLDivElement>;
  onScroll: any;
  focusedChannel: SendBird.GroupChannel;
  walletAddress: string;
  nickname: string;
}) {
  const [prevMsgLoading, setPrevMsgLoading] = useState(false);

  function onScrollWrapper(e: React.UIEvent<HTMLElement>) {
    if (prevMsgLoading) {
      // do not do anything if currently loading
      return;
    }
    const element = e.target as HTMLElement;
    const { scrollTop, clientHeight, scrollHeight } = element;
    if (scrollTop === 0) {
      setPrevMsgLoading(true);

      // @ts-ignore
      const msgNodes = scrollRef?.current?.querySelectorAll(".msg-scroll-ref");
      const firstMsgNode = msgNodes && msgNodes[0];
      onScroll((messages: any) => {
        if (messages) {
          try {
            firstMsgNode?.scrollIntoView();
          } catch (err) {
            sentry.captureGenericMsgEx(
              "Failed to scroll to prev",
              err,
              sentry.FATAL,
              {
                walletAddress,
                focusedChannel,
              }
            );
          }
        }
        setPrevMsgLoading(false);
      });
    }

    setTimeout(() => {
      // mark as read if scroll is at end
      if (clientHeight + scrollTop === scrollHeight) {
        focusedChannel.markAsRead();
      }
    }, 500);
  }

  return (
    <div
      ref={scrollRef}
      className="chat-content px-lg-8 container-scroll-ref"
      onScroll={onScrollWrapper}
    >
      {alert.text === "" ? (
        <div className="container-xxl py-6 py-lg-10">
          {prevMsgLoading ? (
            <i className="fe-loader animate-spin text-4xl inline-block w-100 text-center bg-gradient-to-r from-logo-green-l to-logo-green-r text-transparent bg-clip-text"></i>
          ) : null}
          {!messageWrappers || messageWrappers?.length === 0 ? (
            <NoMessage nickname={nickname} walletAddress={walletAddress} />
          ) : (
            messageWrappers.map((messageWrapper: MessageFormattedWrapper) => {
              return (
                <Message
                  key={messageWrapper.key}
                  senderId={messageWrapper.senderId}
                  senderProfileUrl={messageWrapper.senderProfileUrl}
                  messages={messageWrapper.messages}
                  nickname={nickname}
                />
              );
            })
          )}
        </div>
      ) : (
        <Alert alert={alert} closeAlert={closeAlert} />
      )}
      <div className="end-of-chat"></div>
    </div>
  );
}

export default Content;
