import create, { SetState, GetState } from "zustand";

export enum SidebarType {
  CHATS = "CHATS",
  REQUESTS = "REQUESTS",
}

type SidebarStore = {
  activeSidebar: SidebarType;
  openNav: (nav: SidebarType) => void;
};

export const useSidebarStore = create<SidebarStore>(
  (set: SetState<SidebarStore>, get: GetState<SidebarStore>) => {
    return {
      activeSidebar: SidebarType.CHATS,
      openNav: (nav: SidebarType) => {
        return set({ activeSidebar: nav });
      },
    };
  }
);
