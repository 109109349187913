import { Dispatch, useEffect } from "react";
import SendBird from "sendbird";
import { v4 as uuidv4 } from "uuid";
import compareIds from "utils/compareIds";

import ActionType from "../dux/actionTypes";

/**
 * Handles ChannelEvents and send values to the ChatList dispatcher
 * - should handle events that are related to the chat list sidebar
 */
function useHandleChatListsEvents(
  sb: SendBird.SendBirdInstance,
  chatsListDispatcher: Dispatch<any>
) {
  useEffect(() => {
    const channelReceiverId = uuidv4();

    const channelHandler = new sb.ChannelHandler();

    channelHandler.onChannelChanged = (channel) => {
      chatsListDispatcher({
        type: ActionType.ON_CHANNEL_CHANGED,
        payload: channel,
      });
    };
    channelHandler.onChannelDeleted = (channelUrl) => {
      chatsListDispatcher({
        type: ActionType.ON_CHANNEL_DELETED,
        payload: channelUrl,
      });
    };
    channelHandler.onUserJoined = (channel) => {
      // do not dislay if there are no messages in this conversation
      // - in the case where a conversation is started by the current user
      //   ActionType.CREATE_CHANNEL is used to load into the current view
      if (channel.lastMessage) {
        chatsListDispatcher({
          type: ActionType.ON_USER_JOINED,
          payload: channel,
        });
      }
    };
    channelHandler.onUserBanned = (channel, user) => {
      const { currentUser } = sb;
      chatsListDispatcher({
        type: ActionType.ON_USER_LEFT,
        payload: {
          channel,
          isMe: compareIds(user.userId, currentUser.userId),
        },
      });
    };
    channelHandler.onUserLeft = (channel, leftUser) => {
      const { currentUser } = sb;
      const isMe = compareIds(currentUser.userId, leftUser.userId);
      chatsListDispatcher({
        type: ActionType.ON_USER_LEFT,
        payload: {
          channel,
          isMe,
        },
      });
    };
    channelHandler.onMessageUpdated = (channel, message) => {
      // for the chats list, we only care about the last message
      if (channel.isGroupChannel()) {
        const groupChannel = channel as SendBird.GroupChannel;
        if (groupChannel.lastMessage.isEqual(message)) {
          chatsListDispatcher({
            type: ActionType.ON_LAST_MESSAGE_UPDATED,
            payload: channel,
          });
        }
      }
    };
    channelHandler.onChannelHidden = (channel) => {
      chatsListDispatcher({
        type: ActionType.ON_CHANNEL_ARCHIVED,
        payload: channel.url,
      });
    };
    channelHandler.onChannelFrozen = (channel) => {
      chatsListDispatcher({
        type: ActionType.ON_CHANNEL_FROZEN,
        payload: channel,
      });
    };
    channelHandler.onChannelUnfrozen = (channel) => {
      chatsListDispatcher({
        type: ActionType.ON_CHANNEL_UNFROZEN,
        payload: channel,
      });
    };

    // DEVNOTE: Not added based on SendBird react components
    // onReadStatus, onDeliveryReceiptUpdated ,

    sb.addChannelHandler(channelReceiverId, channelHandler);
    return () => {
      sb.removeChannelHandler(channelReceiverId);
    };
  }, [sb, chatsListDispatcher]);
}

export default useHandleChatListsEvents;
