import React from "react";
import { useNotificationsStore } from "stores/useNotificationsStore";
import Error from "./Error";

function Notifications() {
  const { notifications, hideNotification } = useNotificationsStore(
    (state) => state
  );

  console.log(notifications);
  return (
    <div className="absolute top-0 right-0 m-tw-4 sm:m-5 md:m-6 lg:m-7 xl:m-8 2xl:m-9">
      {notifications.map((notification) => {
        if (notification.type === "error") {
          return (
            <Error
              key={notification.id}
              message={notification.message}
              isVisible={notification.visible}
              closeError={() => hideNotification(notification.id)}
            />
          );
        } else {
          return null;
        }
      })}
    </div>
  );
}

export default Notifications;
