import React from "react";
import Avatar from "./Avatar";
import Body from "./Body";

import { MessageFormatted } from "utils/chat/types";
import { getMyUserId } from "utils/chat/util";

import { useProfileStore } from "stores/useProfileStore";
import useProfileContext from "utils/hooks/useProfileContext";

function Message({
  senderId,
  senderProfileUrl,
  messages,
  nickname,
}: {
  senderId: string;
  senderProfileUrl: string;
  messages: MessageFormatted[];
  nickname: string;
}) {
  const myUserId = getMyUserId();
  const isRight = myUserId === senderId;

  let { displayName, displayProfileUrl } = useProfileContext(
    senderId,
    nickname,
    senderProfileUrl
  );

  // always use profile pic from store if me
  const myUserNickname = useProfileStore((state) => state.currentUserNickname);
  const myProfilePic = useProfileStore(
    (state) => state.currentUserProfilePicUrl
  );
  if (isRight) {
    displayName = myUserNickname;
    displayProfileUrl = myProfilePic;
  }

  return (
    <div className={`message ${isRight ? "message-right" : ""}`}>
      <Avatar isRight={isRight} profileUrl={displayProfileUrl} />
      <Body
        senderId={senderId}
        isRight={isRight}
        messages={messages}
        nickname={displayName}
      />
    </div>
  );
}

export default Message;
