enum ActionType {
  RESET_MESSAGES = "RESET_MESSAGES",

  GET_PREV_MESSAGES_START = "GET_PREV_MESSAGES_START",
  GET_PREV_MESSAGES_SUCCESS = "GET_PREV_MESSAGES_SUCCESS",

  SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS",

  ON_MESSAGE_RECEIVED = "ON_MESSAGE_RECEIVED",
  ON_MESSAGE_UPDATED = "ON_MESSAGE_UPDATED",
  ON_MESSAGE_DELETED = "ON_MESSAGE_DELETED",

  MARK_AS_READ = "MARK_AS_READ",
}

export default ActionType;
