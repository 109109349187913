import React from "react";
// import MobileNav from './MobileNav';

function Toolbar() {
  return (
    <div className="col-3 col-xl-6 text-right">
    {/* <ul className="nav justify-content-end"> */}
        {/* <li className="nav-item list-inline-item d-none d-xl-block mr-3">
            <a className="nav-link text-muted px-3" data-toggle="collapse" data-target="#chat-2-search" href="#/" title="Search this chat">
                <i className="icon-md fe-search"></i>
            </a>
        </li> */}

        {/* <li className="nav-item list-inline-item d-none d-xl-block mr-0">
            <a className="nav-link text-muted px-3" href="#/" data-chat-sidebar-toggle="#chat-2-info" title="Details">
                <i className="icon-md fe-more-vertical"></i>
            </a>
        </li> */}
        {/* <MobileNav /> */}
    {/* </ul> */}
</div>
  );
}

export default Toolbar;
