import { immerable } from "immer";
import { UserProfileSource } from "../graphql/queries";

/**
 * a single users profile, consists of many different sources
 * - and the context of the current logged in user
 */
export class UserProfile {
  [immerable] = true;

  walletAddress: string;
  context: UserProfileSource | null;
  profiles: Map<UserProfileSource, UserProfileFromSource>;

  constructor(walletAddress: string, context: UserProfileSource | null) {
    this.walletAddress = walletAddress;
    this.context = context;
    this.profiles = new Map<UserProfileSource, UserProfileFromSource>();
  }

  /**
   * will update the profile if the soruce already exist, else insert
   */
  insertOrUpdateProfile(
    profileImageUrl: string,
    username: string,
    source: UserProfileSource
  ) {
    this.profiles.set(
      source,
      new UserProfileFromSource(profileImageUrl, username, source)
    );
  }

  /**
   * returns the profile according to the context
   * - returns null if no context
   */
  getProfileWithMainContext() {
    if (!this.context) {
      return null;
    }
    return this.profiles.get(this.context);
  }
}

/**
 * represents a users profile according to a single source
 * - this currently does not include the profile pic / username that are
 *   managed by sendbird
 */
export class UserProfileFromSource {
  [immerable] = true;

  profileUrl: string;
  username: string;

  source: UserProfileSource;

  constructor(profileUrl: string, username: string, source: UserProfileSource) {
    this.profileUrl = profileUrl;
    this.username = username;
    this.source = source;
  }
}
