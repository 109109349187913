import SendBird, { GroupChannel } from "sendbird";

import { useAuthStore } from "stores/useAuthStore";
import { useProfileStore } from "stores/useProfileStore";
import { useFocusedChatStore } from "stores/useFocusedChatStore";
import { usePubSubStore } from "stores/usePubSubStore";
import { useFriendsStore } from "stores/useFriendsStore";

import { CREATE_CHAT } from "../graphql/queries";

import { UserStatus } from "utils/chat/types";
import * as sentry from "utils/sentry";
import Topics from "utils/pubSub/topics";
import { getMyUserId } from "utils/chat/util";
import * as segment from "utils/segment/track";

export type MessageListWrapper = (
  | SendBird.UserMessage
  | SendBird.FileMessage
  | SendBird.AdminMessage
)[];

/**
 * creates a sendbird conversation with the given address
 * - first query backend to make sure that the wallet address already exists
 * - create a group channel with the user
 * - will automatically use the current logged in walletAddress
 * - also add as a sendbird `friend`. The reason for this is to auto populate the conversation into the main list
 */
export async function createConversation(walletAddress: string) {
  const myUserId = getMyUserId();
  const { apolloClient } = useAuthStore.getState();
  await apolloClient.mutate({
    mutation: CREATE_CHAT,
    variables: {
      input: {
        walletAddress,
      },
    },
  });

  const createGroupChannelPromise = createGroupChannel(myUserId, walletAddress);
  // NTF this should be refactored along with the chatdropdown buttons
  const addFriendsPromise = addFriends([walletAddress]);
  const res = await Promise.all([createGroupChannelPromise, addFriendsPromise]);
  useFriendsStore.getState().addFriends(res[1]);
  const pubSub = usePubSubStore.getState().pubSub;
  useFocusedChatStore.getState().setFocusedChannel(res[0] as GroupChannel);
  pubSub.publish(Topics.CREATE_CHANNEL, {
    channel: res[0],
  });
  return res;
}

/**
 * creates a group channel between two wallets
 */
export async function createGroupChannel(
  walletAddressFirst: string,
  walletAddressSecond: string
) {
  const sb = SendBird.getInstance();
  const param = new sb.GroupChannelParams();
  param.addUserIds([walletAddressFirst, walletAddressSecond]);
  param.isDistinct = true;
  return new Promise((resolve, reject) => {
    sb.GroupChannel.createChannel(param, (channel, error) => {
      if (error) {
        return reject(error);
      }
      segment.createConversation(channel);
      return resolve(channel);
    });
  });
}

export async function getGroupChannels() {
  return new Promise((resolve, reject) => {
    const query = genGroupChannelsQuery();

    query.next(function (groupChannels, error) {
      if (error) {
        return reject(error);
      }
      return resolve(groupChannels);
    });
  });
}

/**
 * returns a query that will give us the list of all group channels
 * filtered based on what we want displayed to the user
 */
export function genGroupChannelsQuery() {
  const sb = SendBird.getInstance();

  const query = sb.GroupChannel.createMyGroupChannelListQuery();
  query.includeEmpty = false;
  query.order = "latest_last_message";
  query.limit = 20;

  return query;
}

export function genMessageListQuery() {
  const sb = SendBird.getInstance();

  const query = new sb.MessageListParams();
  query.prevResultSize = 20;
  query.isInclusive = true;
  query.includeReplies = false;
  query.includeReaction = true;

  return query;
}

export async function refreshGroupChannel(
  groupChannel: SendBird.GroupChannel
): Promise<SendBird.GroupChannel> {
  return new Promise((resolve, reject) => {
    groupChannel.refresh((channel, error) => {
      if (error) {
        return reject(error);
      }
      return resolve(channel);
    });
  });
}

export async function blockUserWithUserId(userId: string) {
  return new Promise((resolve, reject) => {
    const sb = SendBird.getInstance();
    sb.blockUserWithUserId(userId, (user, error) => {
      if (error) {
        return reject(error);
      }
      return resolve(user);
    });
  });
}

export async function unblockUserWithUserId(userId: string) {
  return new Promise((resolve, reject) => {
    const sb = SendBird.getInstance();
    sb.unblockUserWithUserId(userId, (user, error) => {
      if (error) {
        return reject(error);
      }
      return resolve(user);
    });
  });
}

/**
 * return list of all users current user has blocked
 */
export async function getBlockedUsers() {
  return new Promise((resolve, reject) => {
    const sb = SendBird.getInstance();
    const q = sb.createBlockedUserListQuery();
    q.next((users, error) => {
      if (error) {
        return reject(error);
      }
      return resolve(users);
    });
  });
}

/**
 * hides a group channel, this temporarily hides the channel from the users view
 * - will reappear when a new message is sent to the group
 */
export async function hideGroupChannel(groupChannel: SendBird.GroupChannel) {
  return new Promise((resolve, reject) => {
    groupChannel.hide(false, true, (res, error) => {
      if (error) {
        return reject(error);
      }
      return resolve(res);
    });
  });
}

/**
 * updates the profile url and nickname of the current user
 * - if no nickname is given (empty string) it will not be updated
 * - if profileImage is empty (null) will not be updated
 */
export async function updateCurrentUserProfileAndNickname(
  nickname: string,
  profileImage: File | undefined
) {
  if (profileImage) {
    // should update profile image
    return await updateCurrentUserInfoWithProfileImage(nickname, profileImage);
  } else {
    // should not update profile image
    return await updateCurrentUserInfo(nickname, "");
  }
}

export async function updateCurrentUserInfo(
  nickname: string,
  profileUrl: string
) {
  return new Promise((resolve, reject) => {
    const sb = SendBird.getInstance();
    sb.updateCurrentUserInfo(nickname, profileUrl, async function (
      user,
      error
    ) {
      if (error) {
        return reject(error);
      }
      useProfileStore.getState().setCurrentUser();
      return resolve(user);
    });
  });
}

export async function updateCurrentUserInfoWithProfileImage(
  nickname: string,
  profileImage: File
) {
  return new Promise((resolve, reject) => {
    const sb = SendBird.getInstance();
    sb.updateCurrentUserInfoWithProfileImage(
      nickname,
      new File([profileImage], profileImage.name, { type: profileImage.type }),
      async function (user, error) {
        if (error) {
          return reject(error);
        }
        useProfileStore.getState().setCurrentUser();
        return resolve(user);
      }
    );
  });
}

export async function addFriends(userIds: string[]): Promise<SendBird.User[]> {
  return new Promise((resolve, reject) => {
    const sb = SendBird.getInstance();
    sb.addFriends(userIds, async function (userList, error) {
      if (error) {
        return reject(error);
      }
      return resolve(userList);
    });
  });
}

export async function deleteFriends(userIds: string[]) {
  return new Promise((resolve, reject) => {
    const sb = SendBird.getInstance();
    sb.deleteFriends(userIds, async function (res, error) {
      if (error) {
        return reject(error);
      }
      return resolve(res);
    });
  });
}

export async function getFriendsIds(): Promise<Set<string>> {
  let friendIds: Set<string> = new Set();
  const friends = await getFriends();
  for (let i = 0; i < friends.length; i++) {
    friendIds.add(friends[i].userId);
  }

  return friendIds;
}

export async function getFriends(): Promise<SendBird.User[]> {
  return new Promise((resolve, reject) => {
    const q = genFriendListQuery();
    q.next(function (userList, error) {
      if (error) {
        return reject(error);
      }
      return resolve(userList);
    });
  });
}

export function genFriendListQuery() {
  const sb = SendBird.getInstance();
  const q = sb.createFriendListQuery();
  return q;
}

/**
 * marks a conversation as read
 */
export async function markAsRead(channelUrl: string) {
  const sb = SendBird.getInstance();
  return new Promise((resolve, reject) => {
    sb.markAsReadWithChannelUrls([channelUrl], (res, error) => {
      if (error) {
        return reject(error);
      }
      return resolve(res);
    });
  });
}

/**
 * get a profile pic of current user
 */
export function getMyProfilePic() {
  const sb = SendBird.getInstance();
  const profileUrl = sb.currentUser?.plainProfileUrl;
  if (profileUrl) {
    return profileUrl;
  }
  sentry.captureSendBirdMsg("user has no profile pic", sentry.WARN);
  return "https://tokitalk.s3.amazonaws.com/doge-1.png";
}

/**
 * get nickname of current user
 */
export function getMyNickname() {
  const sb = SendBird.getInstance();
  const nickname = sb.currentUser?.nickname;
  if (nickname) {
    return nickname;
  }

  sentry.captureSendBirdMsg("user has no nickname", sentry.WARN);
  return "";
}

/**
 * get status of the current user
 * - https://community.sendbird.com/t/sendbird-connection-faq/451
 * - DEVNOTE: connectionStatus is always `notavailable` for currentUser
 * - use getConnectionState instead
 *   - OPEN = connected
 *   - CLOSED = not connected
 *   - CONNECTING = trying to connect, retrying
 */
export function getMyStatus() {
  const sb = SendBird.getInstance();
  const state = sb.getConnectionState();

  if (state === "OPEN") {
    return UserStatus.ONLINE;
  }

  return UserStatus.OFFLINE;
}
