import * as sentry from "utils/sentry";

export function scrollIntoLast(be: ScrollBehavior = "smooth", doc = document) {
  const scrollLastElement = doc.querySelector(".end-of-chat");
  if (scrollLastElement) {
    scrollLastElement.scrollIntoView({
      behavior: be,
    });
  } else {
    // unexpected element not found
    // FATAL because it affects the users ability to converse properly
    sentry.captureGenericMsg(
      "could not find `end-of-chat` class",
      sentry.FATAL
    );
  }
}

export function hasOwnProperty(property: string) {
  return (payload: any) => {
    if (payload && payload.hasOwnProperty && payload.hasOwnProperty(property)) {
      return true;
    }
    return false;
  };
}
