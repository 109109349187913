import { useState, useEffect, useRef } from "react";

// @ts-ignore
import { useFrame } from "react-frame-component";

function useComponentVisible(initialIsVisible: boolean) {
  const doc = useFrame().document || document;

  const [isComponentVisible, setIsComponentVisible] = useState(
    initialIsVisible
  );
  const ref = useRef<HTMLDivElement>(null);

  function handleHideDropdown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      setIsComponentVisible(false);
    }
  }

  function handleClickOutside(event: Event) {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsComponentVisible(false);
    }
  }

  useEffect(() => {
    doc.addEventListener("keydown", handleHideDropdown, true);
    doc.addEventListener("click", handleClickOutside, true);
    return () => {
      doc.removeEventListener("keydown", handleHideDropdown, true);
      doc.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}

export default useComponentVisible;
