import React from "react";
import { ModalType } from "stores/useModalStore";

function ModalBackground({ openedModal }: { openedModal: ModalType | null}) {
  if (openedModal) {
    return <div className="modal-backdrop fade show" />;
  } else {
    return null;
  }
}

export default ModalBackground;
