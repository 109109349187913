import { gql } from "@apollo/client";

// NTF: use codegen
/**
 * all the types of sources that we use to generate a users profile
 */
export enum UserProfileSource {
  SHOWTIME = "showtime",
  OPENSEA = "opensea",
  RARIBLE = "rarible",
  FOUNDATION = "foundation",
}

/**
 * returns a map of supported urls
 */
export const USER_PROFILE_SOURCE_URLS = new Map<UserProfileSource, string>([
  [UserProfileSource.SHOWTIME, "tryshowtime.com"],
  [UserProfileSource.OPENSEA, "opensea.io"],
  [UserProfileSource.RARIBLE, "rarible.com"],
  [UserProfileSource.FOUNDATION, "foundation.app"],
]);

export const AUTH_LOGIN = gql`
  mutation authLogin($input: AuthLoginInput!) {
    authLogin(input: $input) {
      token
      walletAddress
    }
  }
`;

export const GET_AUTH_CHALLENGE = gql`
  query getAuthChallenge($input: AuthChallengeInput!) {
    getAuthChallenge(input: $input) {
      nonce
    }
  }
`;

export const REFRESH_AUTH_LOGIN = gql`
  query refreshAuthLogin {
    refreshAuthLogin {
      token
      walletAddress
    }
  }
`;

export const AUTH_LOGOUT = gql`
  mutation authLogout {
    authLogout
  }
`;

export const CREATE_CHAT = gql`
  mutation createChat($input: CreateChatInput!) {
    createChat(input: $input)
  }
`;

export const SYNC_USER_PROFILE = gql`
  mutation syncUserProfile($input: SyncUserProfileInput!) {
    syncUserProfile(input: $input)
  }
`;

export const USER_PROFILE = gql`
  query userProfile($input: UserProfileInput!) {
    userProfile(input: $input) {
      walletAddress
      profileImageUrl
      username
      source
    }
  }
`;

export const USER_PROFILES = gql`
  query userProfiles($input: UserProfilesInput!) {
    userProfiles(input: $input) {
      userProfiles {
        contextSource
        walletAddress
        userProfiles {
          walletAddress
          profileImageUrl
          username
          source
        }
      }
    }
  }
`;
