import { useEffect } from "react";
// @ts-ignore
import { useFrame } from "react-frame-component";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideClick(ref: any, fn: () => void) {
  const doc = useFrame().document || document;

  useEffect(() => {
    function handleHideDropdown(event: KeyboardEvent) {
      if (event.key === "Escape") {
        fn();
      }
    }

    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        fn();
      }
    }

    // Bind the event listener
    doc.addEventListener("mousedown", handleClickOutside);
    doc.addEventListener("keydown", handleHideDropdown, true);
    return () => {
      // Unbind the event listener on clean up
      doc.removeEventListener("mousedown", handleClickOutside);
      doc.removeEventListener("keydown", handleHideDropdown, true);
    };
  }, [ref, fn, doc]);
}

export default useOutsideClick;
