import { useEffect } from "react";
import * as segment from "utils/segment/track";

function usePageLoad(pageName: string) {
  useEffect(() => {
    segment.page(pageName);
  }, [pageName]);
}

export default usePageLoad;
