import React from "react";
// @ts-ignore
import LogoLoading from "components/LogoLoading"


function Loading() {
  return (
    <div className="flex flex-col justify-center items-center w-full h-full">
      <LogoLoading />
    </div>
  );
}

export default Loading;
