import React, { useRef, useState } from "react";
import Accordion from "./Accordion";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { updateCurrentUserProfileAndNickname } from "utils/chat/action";

function Settings({
  closeSettingsModal,
  isSettingsModalOpen,
}: {
  closeSettingsModal: () => void;
  isSettingsModalOpen: boolean;
}) {
  const modalRef = useRef(null);
  const [isUpdatingSettings, setisUpdatingSettings] = useState(false);
  const [error, setError] = useState("");
  const [image, setImage] = useState<File>();
  const [nickname, setNickname] = useState("");

  useOutsideClick(modalRef, closeSettingsModal);

  async function updateSettings() {
    const tNickname = nickname.trim();
    if (tNickname === "" && image === undefined) {
      setError("Missing nickname or profile picture");
      return;
    }
    setisUpdatingSettings(true);
    try {
      await updateCurrentUserProfileAndNickname(tNickname, image);
      setisUpdatingSettings(false);
      closeSettingsModal();
    } catch (err) {
      // @sean, error
      console.log(err);
      setisUpdatingSettings(false);
      setError(err);
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  }

  return (
    <div
      className={`modal fade ${isSettingsModalOpen ? "show" : ""}`}
      id="invite-friends"
      tabIndex={-1}
      role="dialog"
      aria-hidden={isSettingsModalOpen ? "false" : "true"}
      aria-modal={isSettingsModalOpen ? "true" : "false"}
      style={isSettingsModalOpen ? { display: "block" } : { display: "none" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content" ref={modalRef}>
          <div className="modal-header">
            <div className="media flex-fill">
              <div className="media-body align-self-center">
                <h5 className="modal-title">Settings</h5>
                <p className="small">Update your profile details</p>
              </div>
            </div>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeSettingsModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Accordion
              image={image}
              nickname={nickname}
              setImage={setImage}
              setNickname={setNickname}
              updateSettings={updateSettings}
            />
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className={`${
                isUpdatingSettings
                  ? "bg-gradient-to-r from-btn-green via-btn-teal via-btn-cyan to-btn-blue animate-gradient focus:outline-none outline-none text-white"
                  : error
                  ? "btn-danger"
                  : "btn-primary"
              } btn btn-lg btn-block d-flex align-items-center`}
              style={{ border: "none", backgroundSize: "400% 400%" }}
              onClick={updateSettings}
            >
              {isUpdatingSettings
                ? "Saving Preferences..."
                : error
                ? `${error}`
                : "Save Preferences"}
              <i
                className={`${
                  isUpdatingSettings
                    ? "fe-loader animate-spin"
                    : error
                    ? "fe-alert-triangle"
                    : "fe-sliders"
                } text-xl ml-auto`}
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
