import create, { SetState, GetState } from "zustand";

export enum MobileViewType {
  MAIN = "MAIN",
  SIDEBAR = "SIDEBAR",
}

type MobileViewStore = {
  mobileView: MobileViewType;
  openMain: () => void;
  closeMain: () => void;
};

export const useMobileViewStore = create<MobileViewStore>(
  (set: SetState<MobileViewStore>, get: GetState<MobileViewStore>) => {
    return {
      mobileView: MobileViewType.SIDEBAR,
      openMain: () => {
        return set({ mobileView: MobileViewType.MAIN });
      },
      closeMain: () => {
        return set({ mobileView: MobileViewType.SIDEBAR });
      },
    };
  }
);
