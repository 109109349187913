import { useState, useEffect } from "react";
import { useProfileStore } from "stores/useProfileStore";

/**
 * returns the username and profile url that should be displayed
 */
function useProfileContext(
  walletAddress: string,
  defaultName: string,
  defaultProfileUrl: string
) {
  const [name, setName] = useState(defaultName);
  const [profilePicUrl, setProfilePicUrl] = useState(defaultProfileUrl);

  const userProfiles = useProfileStore((state) =>
    state.profiles.get(walletAddress)
  );
  const cachedUserProfiles = useProfileStore((state) =>
    state.cachedProfiles.get(walletAddress)
  );

  useEffect(() => {
    const cachedUserProfile = cachedUserProfiles?.getProfileWithMainContext();
    if (cachedUserProfile) {
      setName(cachedUserProfile.username);
      setProfilePicUrl(cachedUserProfile.profileUrl);
      return;
    }

    const userProfile = userProfiles?.getProfileWithMainContext();
    if (userProfile) {
      setName(userProfile.username);
      setProfilePicUrl(userProfile.profileUrl);
      return;
    }
    setName(defaultName);
    setProfilePicUrl(defaultProfileUrl);
  }, [userProfiles, cachedUserProfiles, defaultName, defaultProfileUrl]);

  return {
    displayName: name,
    displayProfileUrl: profilePicUrl,
  };
}

export default useProfileContext;
