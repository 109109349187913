import { Dispatch, useEffect } from "react";

import ActionType from "../dux/actionTypes";
import { genGroupChannelsQuery } from "utils/chat/action";
import * as sentry from "utils/sentry";

import { useNotificationsStore } from "stores/useNotificationsStore";

/**
 * fetches the intial chat list, notes that this also resets the existing lists
 */
function useInitialChatsListFetch(chatsListDispatcher: Dispatch<any>) {
  const addNewErrorNotification = useNotificationsStore(
    (state) => state.addNewErrorNotification
  );

  useEffect(() => {
    chatsListDispatcher({
      type: ActionType.INIT_CHANNELS_START,
    });
    const channelListQuery = genGroupChannelsQuery();
    if (channelListQuery.hasNext) {
      channelListQuery.next((response, error) => {
        if (error) {
          addNewErrorNotification("Could not get conversations");
          sentry.captureSendBirdEx(
            "Could not get conversations",
            error,
            sentry.FATAL
          );
          return;
        }
        chatsListDispatcher({
          type: ActionType.INIT_CHANNELS_SUCCESS,
          payload: response,
        });
      });
    }
  }, [addNewErrorNotification, chatsListDispatcher]);
}

export default useInitialChatsListFetch;
