import React, { Suspense, lazy } from "react";
import SendBird from "sendbird";
import { enableMapSet } from "immer";
import { MemoryRouter as Router, Switch } from "react-router-dom";

import "./index.css";
import PrivateRoute from "components/PrivateRoute";
import LoginRoute from "components/LoginRoute";
import Loading from "./pages/Loading";

import { init as initSentry } from "utils/sentry/setup";
import { init as initSegment } from "utils/segment/setup";
import * as config from "utils/config";

let Login: any;
let Chats: any;
if (config.REACT_APP_IS_EXTENSION) {
  Login = require("./pages/Login").default;
  Chats = require("./pages/Chats").default;
} else {
  Chats = lazy(() => import("./pages/Chats"));
  Login = lazy(() => import("./pages/Login"));
}

initSentry();
initSegment();
enableMapSet();

new SendBird({ appId: config.REACT_APP_SENDBIRD_APP_ID });

function App() {
  return (
    <Router>
      {config.REACT_APP_IS_EXTENSION ? (
        <SwitchWrapper />
      ) : (
        <Suspense fallback={<Loading />}>
          <SwitchWrapper />
        </Suspense>
      )}
    </Router>
  );
}

function SwitchWrapper() {
  return (
    <Switch>
      <LoginRoute path="/login">
        <Login />
      </LoginRoute>
      <PrivateRoute path="/">
        <Chats />
      </PrivateRoute>
    </Switch>
  );
}

export default App;
