import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import * as config from "../config";

/**
 * setup sentry
 * - use environment to identify between prod / dev
 * - to disable / enable, set the DSN, undefined = disabled
 */
export function init() {
  Sentry.init({
    // this can be hard coded, we use env to identify production vs development
    // disable when not in production
    dsn: config.IS_PROD
      ? "https://92608ff9761b49ca8abb752a812c5bc8@o821297.ingest.sentry.io/5809447"
      : undefined,

    // enable browser tracing
    integrations: [new Integrations.BrowserTracing()],

    release: config.REACT_APP_COMMIT_HASH,

    environment: config.IS_PROD ? "production" : "development",

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
  });
}
