import create, { SetState, GetState } from "zustand";
import SendBird from "sendbird";
import { getAccessToken } from "../utils/accessToken";

type ChatStore = {
  connect: (userId: string) => void;
  disconnect: () => void;

  // number of unread messages from friend conversations
  unreadMessagesFromFriends: number;
  setUnreadMessagesFromFriends: (numMsgs: number) => void;
};

export const useChatStore = create<ChatStore>(
  (set: SetState<ChatStore>, get: GetState<ChatStore>) => {
    return {
      connect: async (userId: string) => {
        const sb = SendBird.getInstance();
        const token = getAccessToken();

        await sb.connect(userId, token);
      },

      disconnect: async () => {
        const sb = SendBird.getInstance();
        await sb.disconnect();
        set({ unreadMessagesFromFriends: 0 });
      },

      unreadMessagesFromFriends: 0,
      setUnreadMessagesFromFriends: (numMsgs: number) => {
        set({ unreadMessagesFromFriends: numMsgs });
      },
    };
  }
);
