import React from "react";

import ChatsList from "components/Sidebar/ChatsList";
import { useSidebarStore } from "stores/useSidebarStore";

function Sidebar() {
  const { activeSidebar, openNav } = useSidebarStore();

  return (
    <div className="sidebar">
      <div className="tab-content h-100" role="tablist">
        <div
          // DEVNOTE: changed from activeSidebar === "chats" || activeSidebar === "requests" ? "show active" : ""
          className="tab-pane fade h-100 show active"
          id="tab-content-dialogs"
          role="tabpanel"
        >
          <ChatsList activeSidebar={activeSidebar} openNav={openNav} />
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
