import React from "react";

import ChatsLayout from "components/ChatsLayout";
import Modal from "components/Modal";
import ModalBackground from "components/Modal/ModalBackground";
import Notifications from "components/Notifications";

import { useModalStore } from "stores/useModalStore";
import usePageLoad from "utils/hooks/usePageLoad";

// TODO: remove this later and use Header Links for theming
import "../template.css";

function Chats() {
  const openedModal = useModalStore((state) => state.openedModal);
  usePageLoad("Chats");

  return (
    <div className={`${openedModal ? "modal-open" : ""} h-100 w-100`}>
      <ChatsLayout />
      <Modal />
      <ModalBackground openedModal={openedModal} />
      <Notifications />
    </div>
  );
}

export default Chats;
