import React from "react";

function Avatar({
  isRight,
  profileUrl,
}: {
  isRight: boolean;
  profileUrl: string;
}) {
  return (
    <a
      className={`avatar avatar-sm ${
        isRight ? "ml-4 ml-lg-5 d-none d-lg-block" : "mr-4 mr-lg-5"
      }`}
      href="#/"
      data-chat-sidebar-toggle="#chat-1-user-profile"
      onClick={e => e.preventDefault()}
    >
      <img className="avatar-img object-cover" src={profileUrl} alt="" />
    </a>
  );
}

export default Avatar;
