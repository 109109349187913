import React, { useMemo } from "react";

function UploadedImage({
  image,
  reupload,
}: {
  image: File;
  reupload: () => void;
}) {
  // createObjectURL generates a new url everytime it is called
  const imgSrc = useMemo(() => URL.createObjectURL(image), [image]);
  return (
    <div className="flex flex-col items-center mb-5">
      <div className="avatar avatar-xl fixed mb-5">
        <img src={imgSrc} alt="uploaded" />
      </div>
      <button
        type="button"
        className="btn btn-secondary btn-sm"
        onClick={reupload}
      >
        Cancel
      </button>
    </div>
  );
}

export default UploadedImage;
