import React from "react";
import UploadedImage from "./UploadedImage";
import UploadImage from "./UploadImage";

function Accordion({
  image,
  setImage,
  nickname,
  setNickname,
  updateSettings,
}: {
  image: File | undefined;
  setImage: React.Dispatch<React.SetStateAction<File | undefined>>;
  nickname: string;
  setNickname: React.Dispatch<React.SetStateAction<string>>;
  updateSettings: () => void;
}) {
  function renderImageUpload() {
    // Checks if image is a File type - https://stackoverflow.com/questions/31525667/check-if-variable-holds-file-or-blob
    if (
      image &&
      typeof image.name === "string" &&
      typeof image.lastModified === "number"
    ) {
      return (
        <UploadedImage
          image={image}
          reupload={() => {
            setImage(undefined);
          }}
        />
      );
    } else {
      return <UploadImage setImage={setImage} />;
    }
  }

  function handleEnter(e: any) {
    if (e.key === "Enter") {
      e.preventDefault();
      updateSettings();
    }
  }

  return (
    <div className="accordion modified-accordion" id="profile-settings">
      <div className="card">
        <div
          id="profile-settings-account"
          className="collapse show"
          data-parent="#profile-settings"
        >
          <div className="card-body">
            <form action="#">
              {renderImageUpload()}

              <div>
                <label className="small" htmlFor="profile-name">
                  Nickname
                </label>
                <input
                  className="form-control form-control-lg"
                  name="profile-name"
                  id="profile-name"
                  type="text"
                  placeholder="Type your nickname"
                  value={nickname}
                  onChange={(e: any) => setNickname(e.target.value)}
                  onKeyDown={handleEnter}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Accordion;
