import React from "react";
import ChatPhoto from "../../../components/ChatPhoto";
import WalletAddress from "components/WalletAddress";

function Photo({
  name,
  status,
  imgSrc,
  walletAddress,
  isUser,
}: {
  name: string;
  status: string;
  imgSrc: string;
  walletAddress: string;
  isUser: boolean;
}) {
  return (
    <ChatPhoto isUser={isUser}>
      <div
        className={`avatar avatar-sm ${
          status === "online" ? "avatar-online" : "avatar-offline"
        } d-none d-lg-inline-block mr-5`}
      >
        <img src={imgSrc} className="avatar-img object-cover" alt="" />
      </div>

      <div className="media-body align-self-center text-truncate flex flex-col">
        <h6 className="text-truncate mb-n1">
          {name ? `${name}` : <WalletAddress walletAddress={walletAddress} />}
        </h6>
        {/* This is for when u hover on user photo on sidebar it brings the bottom part with it when u copy to clipboard */}
        <div className={isUser ? "self-start" : ""}> 
          <span
            className={`badge badge-dot ${
              status === "online" ? "badge-success" : "badge-tertiary"
            } d-inline-block d-xl-none mr-2`}
          ></span>
          <small className="text-muted ml-1">
            {capitalizeFirstLetter(status)}
          </small>
        </div>
      </div>
    </ChatPhoto>
  );
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default Photo;
