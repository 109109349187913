import { Dispatch, useCallback, useEffect } from "react";
import SendBird from "sendbird";

import ActionType from "../dux/actionTypes";

import { refreshGroupChannel } from "utils/chat/action";
import * as sentry from "utils/sentry";

const RESET_INTERVAL_MS = 1000 * 60 * 3;
/**
 * refreshes all the channels
 * - main use case for this is to refresh all the member states
 *   in the channels
 * - returns a callback function that can be used to manually reset channels
 */
function useResetChannelsCallback(
  groupChannels: SendBird.GroupChannel[],
  chatsListDispatcher: Dispatch<any>
) {
  const resetChannels = useCallback(async () => {
    try {
      let channelPromises: Promise<SendBird.GroupChannel>[] = [];
      for (let i = 0; i < groupChannels.length; i++) {
        channelPromises.push(refreshGroupChannel(groupChannels[i]));
      }
      const newChannels = await Promise.all(channelPromises);
      chatsListDispatcher({
        type: ActionType.REFRESH_CHANNEL_LIST,
        payload: newChannels,
      });
    } catch (error) {
      // silently error as it does not break main functionality
      sentry.captureSendBirdEx("could not refresh channel", error, sentry.WARN);
    }
  }, [groupChannels, chatsListDispatcher]);

  /**
   * reset existing group channels in an interval
   */
  useEffect(() => {
    const interval = setInterval(async () => {
      await resetChannels();
    }, RESET_INTERVAL_MS);
    return () => {
      clearInterval(interval);
    };
  }, [resetChannels]);

  return resetChannels;
}

export default useResetChannelsCallback;
