import ActionType from "./actionTypes";
import initialState from "./initialState";

import compareIds from "utils/compareIds";

import { useFocusedChatStore } from "stores/useFocusedChatStore";

import { hasOwnProperty } from "../utils";

type ReducerAction = {
  type: ActionType;
  payload: any;
};

export default function reducer(state: any, action: ReducerAction) {
  switch (action.type) {
    case ActionType.RESET_MESSAGES:
      return initialState;
    case ActionType.GET_PREV_MESSAGES_START:
      return {
        ...state,
        loading: true,
      };
    case ActionType.GET_PREV_MESSAGES_SUCCESS:
      const receivedMessages = action.payload.messages || [];
      const { focusedChannel = {} } = action.payload;
      const focusedChannelUrl = focusedChannel.url;

      const stateChannelUrl = useFocusedChatStore.getState().focusedChannel
        ?.url;
      if (stateChannelUrl && focusedChannelUrl !== stateChannelUrl) {
        // DEVNOTE: this case is usually for when the focus channel has changed
        // but there was an existing message request already running
        return state;
      }

      // remove duplicate messages
      const filteredAllMessages = state.allMessages.filter(
        // NTF: TYPE
        (msg: any) =>
          !receivedMessages.find(({ messageId }: { messageId: string }) =>
            compareIds(messageId, msg.messageId)
          )
      );

      const hasLatestFetchedMessageTimeStamp = hasOwnProperty(
        "latestFetchedMessageTimeStamp"
      )(action.payload);
      return {
        ...state,
        loading: false,
        hasMore: action.payload.hasMore,
        lastMessageTimeStamp: action.payload.lastMessageTimeStamp,
        // if present change else, keep
        ...(hasLatestFetchedMessageTimeStamp && {
          latestFetchedMessageTimeStamp:
            action.payload.latestFetchedMessageTimeStamp,
        }),
        allMessages: [...receivedMessages, ...filteredAllMessages],
      };
    case ActionType.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        allMessages: [...state.allMessages, { ...action.payload }],
      };
    case ActionType.ON_MESSAGE_RECEIVED:
      const { message } = action.payload;
      const { channel: focusedChannell = {} } = action.payload;
      const focusedChannelUrll = focusedChannell.url;

      const stateChannelUrll = useFocusedChatStore.getState().focusedChannel
        ?.url;
      if (stateChannelUrll && focusedChannelUrll !== stateChannelUrll) {
        // DEVNOTE: this case is usually for when the focus channel has changed
        // but there was an existing message request already running
        return state;
      }

      // Excluded overlapping messages
      if (
        !(
          state.allMessages
            .map((msg: any) => msg.messageId)
            .indexOf(message.messageId) < 0
        )
      ) {
        return state;
      }

      return {
        ...state,
        allMessages: [...state.allMessages, message],
      };
    case ActionType.ON_MESSAGE_DELETED:
      return {
        ...state,
        allMessages: state.allMessages.filter(
          (m: SendBird.UserMessage) => !compareIds(m.messageId, action.payload)
        ),
      };
    default:
      return state;
  }
}
