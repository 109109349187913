/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SendBird from "sendbird";

import {
  addFriends,
  deleteFriends,
  blockUserWithUserId,
  hideGroupChannel,
} from "utils/chat/action";
import { getMyUserId } from "utils/chat/util";
import useComponentVisible from "utils/hooks/useComponentVisible";
import { channelGetMemberIdsWithoutMe } from "utils/chat/util";
import Topics from "utils/pubSub/topics";
import * as sentry from "utils/sentry";

import { useFocusedChatStore } from "stores/useFocusedChatStore";
import { useSidebarStore, SidebarType } from "stores/useSidebarStore";
import { useFriendsStore } from "stores/useFriendsStore";
import { usePubSubStore } from "stores/usePubSubStore";
import { useMobileViewStore } from "stores/useMobileViewStore";
import { useNotificationsStore } from "stores/useNotificationsStore";

function ChatLinkDropdown({
  currChannel,
}: {
  currChannel: SendBird.GroupChannel;
}) {
  const myUserId = getMyUserId();
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);
  const { activeSidebar, openNav } = useSidebarStore();
  const {
    addFriends: addFriendsToStore,
    removeFriendsByUserId,
  } = useFriendsStore();

  const setFocusedChannel = useFocusedChatStore(
    (state) => state.setFocusedChannel
  );
  const pubSub = usePubSubStore((state) => state.pubSub);
  const openMain = useMobileViewStore((state) => state.openMain);
  const addNewErrorNotification = useNotificationsStore(
    (state) => state.addNewErrorNotification
  );

  // NTF refactor these functions with the Footer/Request/index.tsx code

  async function accept(e: any) {
    // stop parent onclick from happening
    e.preventDefault();
    e.stopPropagation();

    // DEVNOTE: probably needs to have similar functionalityy in
    // `MainContent/Chat/Body/Footer/Request/index.tsx
    try {
      const userIdsWithoutMe = channelGetMemberIdsWithoutMe(currChannel);
      const newFriends = await addFriends(userIdsWithoutMe);
      setFocusedChannel(currChannel);
      openMain();
      addFriendsToStore(newFriends);
      openNav(SidebarType.CHATS);
    } catch (err) {
      const errMsg = "Could not accept user";
      sentry.captureSendBirdEx(errMsg, err, sentry.FATAL);
      addNewErrorNotification(errMsg);
    }
  }

  async function ignore(e: any) {
    e.preventDefault();
    e.stopPropagation();

    // DEVNOTE: probably needs to have similar functionality in
    // `MainContent/Chat/Body/Footer/Request/index.tsx
    try {
      const userIdsWithoutMe = channelGetMemberIdsWithoutMe(currChannel);
      await deleteFriends(userIdsWithoutMe);
      setFocusedChannel(null);
      removeFriendsByUserId(userIdsWithoutMe);
    } catch (err) {
      const errMsg = "Could not ignore user";
      sentry.captureSendBirdEx(errMsg, err, sentry.FATAL);
      addNewErrorNotification(errMsg);
    }
  }

  async function del(e: any) {
    e.preventDefault();
    e.stopPropagation();

    // DEVNOTE: probably needs to have similar functionalityy in
    // `MainContent/Chat/Body/Footer/Request/index.tsx
    try {
      await hideGroupChannel(currChannel);
      setFocusedChannel(null);
    } catch (err) {
      const errMsg = "Could not delete user";
      sentry.captureSendBirdEx(errMsg, err, sentry.FATAL);
      addNewErrorNotification(errMsg);
    }
  }

  async function block(e: any) {
    e.preventDefault();
    e.stopPropagation();

    // DEVNOTE: probably needs to have similar functionalityy in
    // `MainContent/Chat/Body/Footer/Request/index.tsx
    // DEVNOTE: this blocks everyone inside the channel
    try {
      const promises: Promise<any>[] = [];
      const blockedUserIds: string[] = [];
      for (let i = 0; i < currChannel.members.length; i++) {
        const userId = currChannel.members[i].userId;
        if (userId !== myUserId) {
          blockedUserIds.push(userId);
          promises.push(blockUserWithUserId(userId));
        }
      }
      await Promise.all(promises);
      setFocusedChannel(null);
      pubSub.publish(Topics.USER_BLOCKED, {
        blockedUserIds,
      });
    } catch (err) {
      const errMsg = "Could not block user";
      sentry.captureSendBirdEx(errMsg, err, sentry.FATAL);
      addNewErrorNotification(errMsg);
    }
  }

  return (
    <div ref={ref} className="align-self-center ml-5">
      <div className={`dropdown ${isComponentVisible ? "show" : ""}`}>
        <button
          className="btn btn-sm btn-ico btn-link text-muted w-auto"
          data-toggle="dropdown"
          aria-expanded="false"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsComponentVisible(!isComponentVisible);
          }}
        >
          <i className="fe-more-vertical"></i>
        </button>
        <div
          className={`dropdown-menu ${isComponentVisible ? "show" : ""}`}
          style={
            isComponentVisible
              ? {
                  position: "absolute",
                  transform: "translate3d(-121px, 36px, 0px)",
                  top: "0px",
                  left: "0px",
                  willChange: "transform",
                }
              : {}
          }
        >
          <a
            className="dropdown-item d-flex align-items-center"
            onClick={activeSidebar === SidebarType.CHATS ? ignore : accept}
          >
            {activeSidebar === SidebarType.CHATS ? (
              <>
                Unfriend <span className="ml-auto fe-user-x"></span>
              </>
            ) : (
              <>
                Accept <span className="ml-auto fe-user-plus"></span>
              </>
            )}
          </a>
          <a className="dropdown-item d-flex align-items-center" onClick={del}>
            Delete <span className="ml-auto fe-eye-off"></span>
          </a>
          <a
            className="dropdown-item d-flex align-items-center"
            onClick={block}
          >
            Block <span className="ml-auto fe-shield"></span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ChatLinkDropdown;
