import React from "react";
import SendBird from "sendbird";
import {
  addFriends,
  blockUserWithUserId,
  hideGroupChannel,
} from "utils/chat/action";
import { getMyUserId, channelGetMemberIdsWithoutMe } from "utils/chat/util";
import Topics from "utils/pubSub/topics";
import FooterContainer from "../FooterContainer";

import { useFocusedChatStore } from "stores/useFocusedChatStore";
import { useFriendsStore } from "stores/useFriendsStore";
import { usePubSubStore } from "stores/usePubSubStore";

function Request({
  focusedChannel,
  setAlert,
  openChats,
}: {
  focusedChannel: SendBird.GroupChannel;
  setAlert: React.Dispatch<
    React.SetStateAction<{
      text: string;
      type: string;
    }>
  >;
  openChats: () => void;
}) {
  const myUserId = getMyUserId();
  const setFocusedChannel = useFocusedChatStore(
    (state) => state.setFocusedChannel
  );
  const { addFriends: addFriendsToStore } = useFriendsStore();
  const pubSub = usePubSubStore((state) => state.pubSub);

  async function accept() {
    // DEVNOTE: similar functionality in ChatLinkDropdown
    try {
      const userIdsWithoutMe = channelGetMemberIdsWithoutMe(focusedChannel);
      const newFriends = await addFriends(userIdsWithoutMe);
      openChats();
      setFocusedChannel(focusedChannel);
      addFriendsToStore(newFriends);
    } catch (err) {
      setAlert({ type: "error", text: "Failed to accept message request." });
    }
  }

  async function del() {
    // DEVNOTE: similar functionality in ChatLinkDropdown
    try {
      await hideGroupChannel(focusedChannel);
      setFocusedChannel(null);
    } catch (err) {
      setAlert({ type: "error", text: "Failed to delete chat." });
    }
  }

  async function block() {
    // DEVNOTE: similar functionality in ChatLinkDropdown
    try {
      const promises: Promise<any>[] = [];
      const blockedUserIds: string[] = [];
      for (let i = 0; i < focusedChannel.members.length; i++) {
        const userId = focusedChannel.members[i].userId;
        if (userId !== myUserId) {
          blockedUserIds.push(userId);
          promises.push(blockUserWithUserId(userId));
        }
      }
      await Promise.all(promises);
      setFocusedChannel(null);
      pubSub.publish(Topics.USER_BLOCKED, {
        blockedUserIds,
      });
    } catch (err) {
      setAlert({ type: "error", text: "Failed to block user." });
    }
  }

  return (
    <FooterContainer>
    <div className="flex items-center w-full justify-evenly">
      <div>
        <button
          onClick={accept}
          className="bg-green-50 text-gray-800 text-base font-medium rounded border-b-2 border-green-500 hover:border-green-600 hover:bg-green-500 hover:text-white shadow-md py-2 px-4 inline-flex items-center focus:outline-none"
        >
          <span>Accept</span>
          <i className={`fe-user-plus text-base ml-3`} />
        </button>
      </div>

      <div>
        <button
          onClick={del}
          className="bg-red-50 text-gray-800 text-base font-medium rounded border-b-2 border-red-500 hover:border-red-600 hover:bg-red-500 hover:text-white shadow-md py-2 px-4 inline-flex items-center focus:outline-none"
        >
          <span>Delete</span>
          <i className={`fe-eye-off text-base ml-3`} />
        </button>
      </div>

      <div>
        <button
          onClick={block}
          className="bg-gray-50 text-gray-800 text-base font-medium rounded border-b-2 border-gray-800 hover:border-gray-900 hover:bg-gray-800 hover:text-white shadow-md py-2 px-4 inline-flex items-center focus:outline-none"
        >
          <span>Block</span>
          <i className={`fe-shield-off text-lg ml-3`} />
        </button>
      </div>
    </div>
    </FooterContainer>
  );
}

export default Request;
