import React, { MouseEvent } from "react";
import SendBird from "sendbird";
import WalletAddress from "components/WalletAddress";

import { useMobileViewStore } from "stores/useMobileViewStore";
import { useFocusedChatStore } from "stores/useFocusedChatStore";

import useProfileContext from "utils/hooks/useProfileContext";

import timeSince from "utils/time";
import {
  getLastMessageDate,
  getLastMessageContent,
  getLastMessageIsMe,
  getOtherMember,
  getUserState,
  getUserProfileUrl,
} from "utils/chat/util";

import ChatLinkDropdown from "./ChatLinkDropdown";

function ChatLink({ channel }: { channel: SendBird.GroupChannel }) {
  const openMain = useMobileViewStore((state: any) => state.openMain);
  const { setFocusedChannel } = useFocusedChatStore();

  const otherMember = getOtherMember(channel);
  const name = otherMember.nickname;
  const walletAddress = otherMember.userId;
  const lastMessageContent = getLastMessageContent(channel);
  const lastMessageDate = getLastMessageDate(channel);
  const lastMessageIsMe = getLastMessageIsMe(channel);
  const numUnreadMessages = channel.unreadMessageCount;
  const status = getUserState(otherMember);
  const onClick = (e: MouseEvent) => {
    e.preventDefault();
    openMain();
    setFocusedChannel(channel);
  };
  const profileUrl = getUserProfileUrl(otherMember);

  const { displayName, displayProfileUrl } = useProfileContext(
    walletAddress,
    name,
    profileUrl
  );

  return (
    <div
      className="text-reset nav-link p-0 mb-6 cursor-pointer"
      onClick={onClick}
    >
      <div className="card card-active-listener">
        <div className="card-body">
          <div className="media">
            <div
              className={`avatar ${
                status === "online" ? "avatar-online" : ""
              } mr-5`}
            >
              <img
                className="avatar-img object-cover"
                src={displayProfileUrl}
                alt={displayName}
              />
            </div>
            <div className="media-body overflow-hidden">
              <div className="d-flex align-items-center mb-1">
                <h6 className="text-truncate mb-0 mr-auto">
                  {" "}
                  {displayName ? (
                    displayName
                  ) : (
                    <WalletAddress walletAddress={walletAddress} />
                  )}
                </h6>
                <p className="small text-muted text-nowrap ml-4">
                  {timeSince(lastMessageDate, true)}
                </p>
              </div>
              <div className="text-truncate">
                {lastMessageIsMe ? <h6 className="d-inline">You: </h6> : null}
                {lastMessageContent}
              </div>
            </div>
            <ChatLinkDropdown currChannel={channel} />
          </div>
        </div>
        {numUnreadMessages !== 0 ? (
          <div className="badge badge-circle badge-primary badge-border-light badge-top-right">
            <span>{numUnreadMessages}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ChatLink;
