import React from "react";
import ChatLoading from "../../ChatLoading";

function ChatsLinkLoading() {
  return (
    <div>
      <ChatLoading />
      <ChatLoading />
      <ChatLoading />
    </div>
  );
}

export default ChatsLinkLoading;
