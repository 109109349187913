// sendbird app id
export const REACT_APP_SENDBIRD_APP_ID = process.env.REACT_APP_SENDBIRD_APP_ID!;

// url of the graphql server to connect to
export const REACT_APP_GRAPHQL_API_URL = process.env.REACT_APP_GRAPHQL_API_URL!;

// used to set the segment key for react, in public/index.html
export const REACT_APP_SEGMENT_WRITE_KEY = process.env
  .REACT_APP_SEGMENT_WRITE_KEY!;

// identifies if the env is prod
export const IS_PROD = process.env.NODE_ENV === "production";

// git commit hash of this build
export const REACT_APP_COMMIT_HASH = process.env.REACT_APP_COMMIT_HASH!;

// if app is from chrome extension
export const REACT_APP_IS_EXTENSION =
  process.env.REACT_APP_IS_EXTENSION! === "true";

const requiredEnvVars = [
  "REACT_APP_SENDBIRD_APP_ID",
  "REACT_APP_GRAPHQL_API_URL",
  "REACT_APP_SEGMENT_WRITE_KEY",
  "REACT_APP_IS_EXTENSION",
  "NODE_ENV",

  // currently set in package.json
  "REACT_APP_COMMIT_HASH",
];

for (let i = 0; i < requiredEnvVars.length; i++) {
  let envvar = requiredEnvVars[i];
  if (!(envvar in process.env)) {
    throw new Error(`Required env var is not set: ${envvar}`);
  }
}
