import React from "react";
import SendBird from "sendbird";
import { useHistory } from "react-router-dom";

// NTF: clean move Photo to its own component
import Photo from "components/MainContent/Chat/Body/Header/Photo";

import { useAuthStore } from "stores/useAuthStore";
import { useProfileStore } from "stores/useProfileStore";
import { ModalType, useModalStore } from "stores/useModalStore";

import useHandleUserEvents from "utils/hooks/useHandleUserEvents";
import { getMyUserId } from "utils/chat/util";

function User() {
  const history = useHistory();
  const myUserId = getMyUserId();
  const sb = SendBird.getInstance();

  // NTF this is kinda jank, we should add a global store initialization
  // https://www.notion.so/initialize-global-store-on-login-579b282973df407ea9a094bedd7b48ce
  useHandleUserEvents(sb);
  const authLogout = useAuthStore((state) => state.logout);
  const myStatus = useProfileStore((state) => state.currentUserStatus);
  const setModal = useModalStore((state) => state.setModal);
  const myProfilePic = useProfileStore(
    (state) => state.currentUserProfilePicUrl
  );
  const myNickname = useProfileStore((state) => state.currentUserNickname);

  return (
    <div className="card">
      <div className="card-body">
        <div className="media align-items-center justify-between">
          <Photo
            isUser={true}
            name={myNickname}
            imgSrc={myProfilePic}
            status={myStatus}
            walletAddress={myUserId}
          />
          <div className="flex ml-8">
            <i
              onClick={() => setModal(ModalType.SETTINGS)}
              className={`hover:text-primary hover:animate-spin cursor-pointer text-3xl fe-settings`}
            ></i>
            <i
              onClick={async () => authLogout(() => history.push("/login"))}
              className="hover:text-primary pl-6 fe-log-out text-3xl cursor-pointer hover:animate-bounceRight"
            ></i>
          </div>
        </div>
      </div>
    </div>
  );
}

export default User;
