import React from "react";

function ChatHeader({ children }: { children: React.ReactNode }) {
  return (
    <div className="chat-header border-bottom py-4 py-lg-6 px-lg-8">
      <div className="container-xxl">
        <div className="row align-items-center">{children}</div>
      </div>
    </div>
  );
}

export default ChatHeader;
