import React, { useEffect } from "react";

import AddChatModal from "./AddChatModal";
import SettingsModal from "./SettingsModal";

import { ModalType, useModalStore } from "stores/useModalStore";
import useNewChatStore from "stores/useNewChatStore";

function Modal() {
  const { openedModal, setModal } = useModalStore();
  const {
    profilePicUrl,
    walletAddress: newWalletAddress,
    originUrl,
  } = useNewChatStore();

  useEffect(() => {
    if (newWalletAddress && profilePicUrl && originUrl) {
      if (!openedModal) {
        setModal(ModalType.ADD_CHAT);
      }
    }
  }, [openedModal, setModal, profilePicUrl, newWalletAddress, originUrl]);

  if (openedModal === ModalType.ADD_CHAT) {
    return (
      <AddChatModal
        closeAddChatModal={() => setModal(null)}
        isAddChatModalOpen={openedModal === ModalType.ADD_CHAT}
      />
    );
  } else if (openedModal === ModalType.SETTINGS) {
    return (
      <SettingsModal
        closeSettingsModal={() => setModal(null)}
        isSettingsModalOpen={openedModal === ModalType.SETTINGS}
      />
    );
  }
  return null;
}

export default Modal;
