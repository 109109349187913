import React from "react";
import Content from "./Content";

function Row({
  index,
  content,
  timeSent,
  isRight,
  senderId,
  nickname
}: {
  index: number;
  content: string;
  timeSent: Date;
  isRight: boolean;
  senderId: string;
  nickname: string
}) {
  return (
    <div className="message-row msg-scroll-ref">
      <div
        className={`d-flex align-items-center ${
          isRight ? "justify-content-end" : ""
        }`}
      >
        <Content
          index={index}
          content={content}
          timeSent={timeSent}
          isRight={isRight}
          senderId={senderId}
          nickname={nickname}
        />
      </div>
    </div>
  );
}

export default Row;
