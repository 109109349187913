enum ActionType {
  RESET_CHANNEL_LIST = "RESET_CHANNEL_LIST",
  REFRESH_CHANNEL_LIST = "REFRESH_CHANNEL_LIST",

  INIT_CHANNELS_START = "INIT_CHANNELS_START",
  INIT_CHANNELS_SUCCESS = "INIT_CHANNELS_SUCCESS",

  ON_USER_JOINED = "ON_USER_JOINED",
  ON_USER_LEFT = "ON_USER_LEFT",
  ON_USER_BLOCKED = "ON_USER_BLOCKED",

  CREATE_CHANNEL = "CREATE_CHANNEL",

  ON_CHANNEL_CHANGED = "ON_CHANNEL_CHANGED",
  ON_CHANNEL_DELETED = "ON_CHANNEL_DELETED",
  ON_CHANNEL_ARCHIVED = "ON_CHANNEL_ARCHIVED",
  ON_CHANNEL_FROZEN = "ON_CHANNEL_FROZEN",
  ON_CHANNEL_UNFROZEN = "ON_CHANNEL_UNFROZEN",

  ON_LAST_MESSAGE_UPDATED = "ON_LAST_MESSAGE_UPDATED",
}

export default ActionType;
