import React from "react";
import Close from "../../../components/Close";
import Photo from "./Photo";
import Toolbar from "./Toolbar";
import ChatHeader from "../../../components/ChatHeader";

function Header({
  name,
  status,
  imgSrc,
  walletAddress,
  isUser,
}: {
  name: string;
  status: string;
  imgSrc: string;
  walletAddress: string;
  isUser: boolean;
}) {

  return (
    <ChatHeader>
      <Close />
      <Photo
        name={name}
        status={status}
        imgSrc={imgSrc}
        walletAddress={walletAddress}
        isUser={isUser}
      />
      <Toolbar />
    </ChatHeader>
  );
}

export default Header;
