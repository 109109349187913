import React from "react";
import SendBird from "sendbird";

import ChatLink from "./ChatLink";

function ChatsLinkList({
  channels,
}: {
  channels: SendBird.GroupChannel[];
}) {
  return (
    <nav className="nav d-block list-discussions-js mb-n6">
      {channels.map((channel: SendBird.GroupChannel) => {
        return (
          <ChatLink
            key={channel.url}
            channel={channel}
          />
        );
      })}
    </nav>
  );
}

export default ChatsLinkList;
