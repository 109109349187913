import React from "react";
import SendBird from "sendbird";
import { SidebarType, useSidebarStore } from "stores/useSidebarStore";
import SendMessage from "./SendMessage";
import Request from "./Request";

function Footer({
  setAlert,
  focusedChannel,
  sendUserMessage,
}: {
  setAlert: React.Dispatch<
    React.SetStateAction<{
      text: string;
      type: string;
    }>
  >;
  focusedChannel: SendBird.GroupChannel;
  sendUserMessage: (msg: string) => void;
}) {
  const focusedChannelUrl = focusedChannel.url;

  const { activeSidebar, openNav } = useSidebarStore();
  if (activeSidebar === SidebarType.CHATS && focusedChannelUrl) {
    return (
      <SendMessage setAlert={setAlert} sendUserMessage={sendUserMessage} />
    );
  } else if (activeSidebar === SidebarType.REQUESTS && focusedChannelUrl) {
    return (
      <Request
        setAlert={setAlert}
        focusedChannel={focusedChannel}
        openChats={() => openNav(SidebarType.CHATS)}
      />
    );
  } else {
    return null;
  }
}

export default Footer;
