import create, { SetState, GetState } from "zustand";
import SendBird from "sendbird";

type FocusedChatStore = {
  focusedChannel: SendBird.GroupChannel | null;
  setFocusedChannel: (gc: SendBird.GroupChannel | null) => void;
};

export const useFocusedChatStore = create<FocusedChatStore>(
  (set: SetState<FocusedChatStore>, get: GetState<FocusedChatStore>) => {
    return {
      focusedChannel: null,
      setFocusedChannel: (channel: SendBird.GroupChannel | null) => {
        set({ focusedChannel: channel });
      },
    };
  }
);
