import React, { useState } from "react";

function Alert({
  alert,
  closeAlert,
}: {
  alert: { text: string; type: string };
  closeAlert: () => void;
}) {
  const [isShow, setIsShow] = useState(true);
  let color = alertColorPicker(alert.type);

  if (isShow) {
    return (
      <div className={`rounded-md ${color["bg-50"]} p-4 mt-9`}>
        <div className="flex items-center">
          <div className="flex-shrink-0">
            {AlertIcon(alert.type, color)}
          </div>
          <div className="ml-4">
            <p className={`text-xl font-medium ${color["text-800"]}`}>
              {alert.text}
            </p>
          </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  onClick={() => setIsShow(false)}
                  className={`inline-flex ${color["bg-50"]} rounded-md p-1.5 ${color["text-500"]}`}
                >
                  <span className="sr-only">Dismiss</span>
                  <i
                    className={`fe-x text-3xl ${color["text-400"]}`}
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

function AlertIcon(type: string, color: any) {
  if (type === "error") {
    return (
      <i
        className={`fe-x-circle text-3xl ${color["text-400"]}`}
        aria-hidden="true"
    />
    )
  } else {
    return (
      <i
      className={`fe-info text-3xl ${color["text-400"]}`}
      aria-hidden="true"
  />
    )
  }
}

function alertColorPicker(type: string) {
  const color = {
    "bg-50": "bg-indigo-50",
    "text-800": "text-indigo-800",
    "text-400": "text-indigo-400",
    "text-500": "text-indigo-500",
  };

  if (type === "error") {
    color["bg-50"] = "bg-red-50";
    color["text-800"] = "text-red-800";
    color["text-400"] = "text-red-400";
    color["text-500"] = "text-red-500";
  } else if (type === "default") {
    color["bg-50"] = "bg-green-50";
    color["text-800"] = "text-green-800";
    color["text-400"] = "text-green-400";
    color["text-500"] = "text-red-500";
  }

  return color;
}

export default Alert;
