import React from "react";

import Body from "./Body";
import ChatsMain from "./ChatsMain";
import RequestsMain from "./RequestsMain";

import { useFocusedChatStore } from "stores/useFocusedChatStore";
import { useMobileViewStore, MobileViewType } from "stores/useMobileViewStore";
import { SidebarType } from "stores/useSidebarStore";

function Chat({ activeSidebar }: { activeSidebar: SidebarType }) {
  const focusedChannel = useFocusedChatStore((state) => state.focusedChannel);
  const mobileView = useMobileViewStore((state) => state.mobileView);

  if (!focusedChannel) {
    if (mobileView === MobileViewType.MAIN) {
      return null;
    } else if (activeSidebar === SidebarType.CHATS) {
      return <ChatsMain />;
    } else if (activeSidebar === SidebarType.REQUESTS) {
      return <RequestsMain />;
    } else {
      return null;
    }
  } else {
    return (
      <div
        id="chat-1"
        className="chat dropzone-form-js"
        data-dz-url="@TODO: fix this dropzone"
      >
        <Body focusedChannel={focusedChannel} />
      </div>
    );
  }
}

export default Chat;
