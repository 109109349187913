import React from "react";
import { useModalStore } from "stores/useModalStore";

function MessageTextArea({
  message,
  setMessage,
  sendMessage,
}: {
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  sendMessage: () => Promise<void>;
}) {
  const openedModal = useModalStore((state) => state.openedModal);

  return (
    <textarea
      id="chat-id-2-input"
      style={{
        overflow: "hidden",
        overflowWrap: "break-word",
        resize: "none",
        height: "46px",
      }}
      className="form-control bg-transparent border-0"
      autoFocus
      placeholder="Type your message..."
      rows={1}
      data-emoji-input=""
      data-autosize="true"
      value={message}
      onChange={(e: any) => setMessage(e.target.value)}
      onKeyDown={(e: any) => {
        // Enter message on enter key
        if (e.keyCode === 13 && e.shiftKey === false) {
          e.preventDefault();
          sendMessage();
        }
      }}
      onBlur={({ target }) => {
        if (!openedModal) {
          // DEVNOTE dont focus when modal open
          target.focus();
        }
      }}
    ></textarea>
  );
}

export default MessageTextArea;
