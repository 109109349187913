import create, { SetState, GetState } from "zustand";
import produce from "immer";
import {
  getMyStatus,
  getMyNickname,
  getMyProfilePic,
} from "../utils/chat/action";
import { UserStatus } from "../utils/chat/types";
import { UserProfile } from "../utils/profile/types";
import { UserProfileSource } from "../utils/graphql/queries";
import { utils } from "ethers";

type ProfileStore = {
  resetCurrentUser: () => void;
  setCurrentUser: () => void;

  currentUserProfilePicUrl: string;
  setCurrentUserProfilePicUrl: () => void;
  setCurrentUserProfilePicUrlWithValue: (profilePicUrl: string) => void;

  currentUserNickname: string;
  setCurrentUserNickname: () => void;

  // this is to track the current user status
  currentUserStatus: UserStatus;
  setCurrentUserStatus: () => void;
  setCurrentUserStatusWithValue: (newStatus: UserStatus) => void;

  // map of profiles of users that are in the current users
  // conversations
  // key: walletAddress / sendbird id
  profiles: Map<string, UserProfile>;
  setNewProfiles: (p: Map<string, UserProfile>) => void;

  // cached profiles, this is most probably gotten from the query params passed through chrome extension
  // this should be preferred if it exists
  // key: walletAddress / sendbird id
  cachedProfiles: Map<string, UserProfile>;
  setCachedProfileWithMetadata: (
    walletAddress: string,
    source: UserProfileSource,
    profileImgUrl: string,
    username: string
  ) => void;
};

export const useProfileStore = create<ProfileStore>(
  (set: SetState<ProfileStore>, get: GetState<ProfileStore>) => ({
    resetCurrentUser: () => {
      set({ currentUserProfilePicUrl: "" });
      set({ currentUserNickname: "" });
      set({ currentUserStatus: UserStatus.OFFLINE });
    },
    setCurrentUser: () => {
      useProfileStore.getState().setCurrentUserNickname();
      useProfileStore.getState().setCurrentUserProfilePicUrl();
      useProfileStore.getState().setCurrentUserStatus();
    },

    currentUserProfilePicUrl: "",
    setCurrentUserProfilePicUrl: () => {
      set({ currentUserProfilePicUrl: getMyProfilePic() });
    },
    setCurrentUserProfilePicUrlWithValue: (profilePicUrl: string) => {
      set({ currentUserProfilePicUrl: profilePicUrl });
    },

    currentUserNickname: "",
    setCurrentUserNickname: () => {
      set({ currentUserNickname: getMyNickname() });
    },

    currentUserStatus: UserStatus.OFFLINE,
    setCurrentUserStatus: () => {
      const newUserStatus = getMyStatus();
      set({ currentUserStatus: newUserStatus });
    },
    setCurrentUserStatusWithValue: (newStatus: UserStatus) => {
      set({ currentUserStatus: newStatus });
    },

    profiles: new Map<string, UserProfile>(),
    setNewProfiles: (p: Map<string, UserProfile>) => {
      set({ profiles: p });
    },

    cachedProfiles: new Map<string, UserProfile>(),
    setCachedProfileWithMetadata: (
      walletAddress: string,
      source: UserProfileSource,
      profileImageUrl: string,
      username: string
    ) => {
      let formattedWalletAddress = utils.getAddress(walletAddress);
      set(
        produce((state) => {
          if (!state.cachedProfiles.get(formattedWalletAddress)) {
            // if dne, create
            state.cachedProfiles.set(
              formattedWalletAddress,
              new UserProfile(formattedWalletAddress, source)
            );
          }
          state.cachedProfiles
            .get(formattedWalletAddress)
            .insertOrUpdateProfile(profileImageUrl, username, source);
        })
      );
    },
    setNewCachedProfiles: (p: Map<string, UserProfile>) => {
      set({ profiles: p });
    },
  })
);
