function isEmpty(val: any) {
  return val === null || val === undefined;
}

// Some SendBird Ids return string and number inconsistently
// only use to compare IDs
function compareIds(a: string | number, b: string | number) {
  if (isEmpty(a) || isEmpty(b)) {
    return false;
  }
  const aString = a.toString();
  const bString = b.toString();

  return aString === bString;
}

export default compareIds;
