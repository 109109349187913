import SendBird, { UserMessage, AdminMessage, FileMessage } from "sendbird";
import * as sentry from "../sentry";
import { ChatFormatted } from "./types";

export function getMyUserId() {
  const sb = SendBird.getInstance();
  return sb.currentUser?.userId;
}

export function getMyProfileUrl() {
  const sb = SendBird.getInstance();
  return sb.currentUser?.profileUrl;
}

/**
 * given a channel object, return the member that is NOT the current user
 * - only works for 1-1 GC
 * - returns the first member that is not the current member
 * - throw error if not found
 */
export function getOtherMember(channel: SendBird.GroupChannel) {
  const myUserId = getMyUserId();
  const allMembers = channel.members;

  for (let i = 0; i < allMembers.length; i++) {
    if (allMembers[i].userId !== myUserId) {
      return allMembers[i];
    }
  }

  sentry.captureSendBirdMsg(
    "could not find other member in sendbird channel",
    sentry.FATAL,
    {
      groupChannelUri: channel.url,
    }
  );
  throw new Error("unexpected error");
}

export function getLastMessageDate(
  channel: SendBird.GroupChannel
): Date | null {
  const createdAt = channel.lastMessage?.createdAt;
  if (createdAt) {
    return new Date(createdAt);
  }
  return null;
}

/**
 * returns the last message user id
 * - because Admin Messages do not have a user id attached, return "admin"
 */

export function getLastMessageUserId(
  lastMessage: UserMessage | FileMessage | AdminMessage
): string {
  if (lastMessage.isAdminMessage()) {
    return "admin";
  }
  const convLastMessage = lastMessage as
    | SendBird.UserMessage
    | SendBird.FileMessage;
  return convLastMessage?.sender?.userId;
}

export function getLastMessageIsMe(channel: SendBird.GroupChannel): boolean {
  if (!channel.lastMessage) {
    return false;
  }
  const myUserId = getMyUserId();
  const lastMessageUserId = getLastMessageUserId(channel.lastMessage);
  return myUserId === lastMessageUserId;
}

export function getLastMessageContent(channel: SendBird.GroupChannel): string {
  const lastMessage = channel.lastMessage;
  if (!lastMessage) {
    return "";
  }

  if (lastMessage.isUserMessage()) {
    const userLastMessage = lastMessage as UserMessage;
    return userLastMessage?.message;
  } else if (lastMessage.isAdminMessage()) {
    const adminLastMessage = lastMessage as AdminMessage;
    return adminLastMessage?.message;
  } else if (lastMessage.isFileMessage()) {
    const fileLastMessage = lastMessage as FileMessage;
    return fileLastMessage?.name;
  }

  sentry.captureSendBirdMsg("lastMessage does not have content", sentry.FATAL, {
    messageId: lastMessage.messageId,
  });
  return "";
}

/**
 * returns the users state, this should be limited to the values that the UI can handle
 * - Admin Messages will be considered `offline`
 */
export function getLastMessageUserState(
  channel: SendBird.GroupChannel
): string {
  const lastMessage = channel.lastMessage;
  if (!lastMessage) {
    return "offline";
  }

  if (lastMessage.isAdminMessage()) {
    return "offline";
  }

  const convLastMessage = lastMessage as UserMessage | FileMessage;
  return getUserState(convLastMessage.sender);
}

/**
 * returns the users state, this should be limited to the values that the UI can handle
 */
export function getUserState(user: SendBird.User) {
  if (user.connectionStatus === "online") {
    return "online";
  }
  return "offline";
}

export function getUserProfileUrl(user: SendBird.User) {
  if (user.profileUrl === "") {
    sentry.captureSendBirdMsg("user has no profile pic", sentry.WARN);
    return "https://tokitalk.s3.amazonaws.com/doge-1.png";
  }
  return user.profileUrl;
}

// NTF REFACTOR THIS so we can remove types.ts
export function formatMessageWrappers(channel: any, messages: any[]) {
  let chatFormatted = new ChatFormatted(channel);

  for (let i = 0; i < messages.length; i++) {
    chatFormatted.insertMessage(messages[i]);
  }

  return chatFormatted.messageFormattedWrappers;
}

/**
 * removes all channels that contain members that the current user has
 * blocked
 */
export function filterChannelsWithoutBlockedMembers(
  channels: SendBird.GroupChannel[]
) {
  let withoutBlockedMembers: SendBird.GroupChannel[] = [];
  for (let i = 0; i < channels.length; i++) {
    const channel = channels[i];
    if (!hasBlockedMember(channel.members)) {
      withoutBlockedMembers.push(channel);
    }
  }

  return withoutBlockedMembers;
}

/**
 * checks if the given members list has a blocked member
 */
export function hasBlockedMember(members: SendBird.Member[]) {
  return members.some((member: SendBird.Member) => member.isBlockedByMe);
}

/**
 * returns two lists, one with channels that have friends in them, the other without
 */
export function filterChannelsByFriends(
  channels: SendBird.GroupChannel[],
  friends: Map<string, SendBird.User>
) {
  let withFriends: SendBird.GroupChannel[] = [];
  let withNoFriends: SendBird.GroupChannel[] = [];

  for (let i = 0; i < channels.length; i++) {
    if (channelHasAtLeastOneFriend(channels[i], friends)) {
      withFriends.push(channels[i]);
    } else {
      withNoFriends.push(channels[i]);
    }
  }

  return { withFriends, withNoFriends };
}

export function channelHasAtLeastOneFriend(
  channel: SendBird.GroupChannel,
  friends: Map<string, SendBird.User>
) {
  const allMembers = channel.members;
  for (let i = 0; i < allMembers.length; i++) {
    const member = allMembers[i];
    if (friends.has(member.userId)) {
      return true;
    }
  }
  return false;
}

/**
 * returns a list of members without the current user
 */
export function channelGetMembersWithoutMe(
  channel: SendBird.GroupChannel
): SendBird.User[] {
  const myUserId = getMyUserId();
  return channel.members.filter(
    (member: SendBird.User) => member.userId !== myUserId
  );
}

/**
 * returns a list of members ids without the current user
 */
export function channelGetMemberIdsWithoutMe(
  channel: SendBird.GroupChannel
): string[] {
  return channelGetMembersWithoutMe(channel).map(
    (member: SendBird.User) => member.userId
  );
}

export function channelsGetMemberIdsWithoutMe(
  channels: SendBird.GroupChannel[]
): Set<string> {
  let allMemberIds: Set<string> = new Set();
  for (let i = 0; i < channels.length; i++) {
    const newMemberIds = channelGetMemberIdsWithoutMe(channels[i]);
    newMemberIds.forEach((memberId) => allMemberIds.add(memberId));
  }
  return allMemberIds;
}
