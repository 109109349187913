import React, { useEffect } from "react";

function Error({
  message,
  closeError,
  isVisible
}: {
  message: string;
  closeError: () => void;
  isVisible: boolean
}) {
  useEffect(() => {
    if(isVisible) {
      setTimeout(() => {
        closeError();
      }, 5000)
    }
  }, [isVisible, closeError])

  return (
    <div
      className={`toast transition-all ${isVisible ? "show" : "hide"}`}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div className="toast-header">
        <i className="fe-alert-triangle text-red-400 mr-3"></i>
        <strong className="mr-auto text-gray-800">Error</strong>
        <button
          type="button"
          className="ml-2 mb-1 close"
          data-dismiss="toast"
          aria-label="Close"
          onClick={closeError}
        >
          <span className="text-gray-900" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="toast-body text-gray-500">{message}</div>
    </div>
  );
}

export default Error;
