import { Dispatch, useCallback } from "react";
import SendBird, { SendBirdInstance } from "sendbird";
// @ts-ignore
import { useFrame } from "react-frame-component";

import ActionType from "../dux/actionTypes";

import { scrollIntoLast } from "../utils";
import * as segment from "utils/segment/track";
import * as sentry from "utils/sentry";

import { useNotificationsStore } from "stores/useNotificationsStore";

function useSendMessageCallback(
  sb: SendBirdInstance,
  focusedChannel: SendBird.GroupChannel,
  messagesDispatcher: Dispatch<any>
): any {
  const iframeDoc = useFrame().document;
  const addNewErrorNotification = useNotificationsStore(
    (state) => state.addNewErrorNotification
  );

  return useCallback(
    (newMsg: string) => {
      const msg = typeof newMsg === "string" ? newMsg.trim() : newMsg;
      const params = new sb.UserMessageParams();
      params.message = msg;

      focusedChannel.sendUserMessage(params, (message, error) => {
        if (error) {
          sentry.captureSendBirdEx(
            "Could not send messages",
            error,
            sentry.FATAL
          );
          addNewErrorNotification("Could not send message");
          return;
        }

        messagesDispatcher({
          type: ActionType.SEND_MESSAGE_SUCCESS,
          payload: message,
        });

        segment.messageSent(message);

        // DEVNOTE: when implementing pending messages in the future
        // we will probably need to move this to after the pending message
        // dispatch event
        setTimeout(() => scrollIntoLast("smooth", iframeDoc));
      });
    },
    [sb, focusedChannel, messagesDispatcher, iframeDoc, addNewErrorNotification]
  );
}

export default useSendMessageCallback;
