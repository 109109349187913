import create, { SetState, GetState } from "zustand";
import SendBird from "sendbird";
import produce from "immer";

import { getFriends } from "utils/chat/action";
import * as sentry from "utils/sentry";

type FriendsStore = {
  friends: Map<string, SendBird.User>;
  addFriends: (newFriends: SendBird.User[]) => void;
  removeFriendsByUserId: (oldFriendUserIds: string[]) => void;
  resetFriends: (allFriends: SendBird.User[]) => void;
  resetFriendsReal: () => void;
};

export const useFriendsStore = create<FriendsStore>(
  (set: SetState<FriendsStore>, get: GetState<FriendsStore>) => {
    return {
      friends: new Map<string, SendBird.User>(),
      addFriends: (newFriends: SendBird.User[]) => {
        set(
          produce((state) => {
            newFriends.forEach((newFriend: SendBird.User) => {
              state.friends.set(newFriend.userId, newFriend);
            });
          })
        );
      },
      removeFriendsByUserId: (oldFriendUserIds: string[]) => {
        set(
          produce((state) => {
            oldFriendUserIds.forEach((oldFriendUserId: string) => {
              state.friends.delete(oldFriendUserId);
            });
          })
        );
      },
      resetFriends: (allFriends: SendBird.User[]) => {
        const allFriendsMap = new Map();
        allFriends.forEach((friend: SendBird.User) => {
          allFriendsMap.set(friend.userId, friend);
        });
        set({ friends: allFriendsMap });
      },
      resetFriendsReal: async () => {
        // DEVNOTE: please check that all the functions that call this handle errors properly
        // if we throw an error here
        try {
          const friends = await getFriends();
          useFriendsStore.getState().resetFriends(friends);
        } catch (error) {
          sentry.captureSendBirdEx(
            "could not refresh friends",
            error,
            sentry.FATAL
          );
        }
      },
    };
  }
);
