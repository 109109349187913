import React from "react";

function FooterContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="chat-footer border-top py-4 py-lg-6 px-lg-8">
      <div className="container-xxl">
        { children }
      </div>
    </div>
  );
}

export default FooterContainer;
