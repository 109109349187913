import {
  UserProfileSource,
  USER_PROFILE_SOURCE_URLS,
} from "../graphql/queries";
import * as sentry from "../sentry";

/**
 * given a url, get the user source
 */
export function getSourceFromUrl(url: string) {
  if (url.includes(USER_PROFILE_SOURCE_URLS.get(UserProfileSource.SHOWTIME)!)) {
    return UserProfileSource.SHOWTIME;
  } else if (
    url.includes(USER_PROFILE_SOURCE_URLS.get(UserProfileSource.OPENSEA)!)
  ) {
    return UserProfileSource.OPENSEA;
  } else if (
    url.includes(USER_PROFILE_SOURCE_URLS.get(UserProfileSource.RARIBLE)!)
  ) {
    return UserProfileSource.RARIBLE;
  } else if (
    url.includes(USER_PROFILE_SOURCE_URLS.get(UserProfileSource.FOUNDATION)!)
  ) {
    return UserProfileSource.FOUNDATION;
  } else {
    sentry.captureGenericMsg("Unexpected query param url found", sentry.WARN, {
      originUrl: url,
    });
    throw new Error("unexpected url: " + url);
  }
}
