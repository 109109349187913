import { useEffect } from "react";

import { useFriendsStore } from "stores/useFriendsStore";
import { useFocusedChatStore } from "stores/useFocusedChatStore";

const RESET_INTERVAL_MS = 1000 * 60 * 5;

/**
 * manages the current users friends
 * - initializes the friends list
 * - updates the friends list whenever a user changes a conversation
 * - periodically calls the friends list
 */
function useResetFriends() {
  const resetFriendsReal = useFriendsStore((state) => state.resetFriendsReal);
  useEffect(() => {
    // initialize friends
    resetFriendsReal();
  }, [resetFriendsReal]);

  useEffect(() => {
    // update friends list whenever the focused channel changes
    const updateFriendsUnsub = useFocusedChatStore.subscribe(
      (
        focusedChannelUrl: string | undefined,
        prevFocusedChannelUrl: string | undefined
      ) => {
        // only update friends list if the new channel is different and exists
        if (focusedChannelUrl && focusedChannelUrl !== prevFocusedChannelUrl) {
          resetFriendsReal();
        }
      },
      (state) => state.focusedChannel?.url
    );
    return () => {
      updateFriendsUnsub();
    };
  }, [resetFriendsReal]);

  useEffect(() => {
    // update friends list at an interval
    const interval = setInterval(async () => {
      resetFriendsReal();
    }, RESET_INTERVAL_MS);

    return () => {
      clearInterval(interval);
    };
  }, [resetFriendsReal]);
}

export default useResetFriends;
