import { Dispatch, useEffect } from "react";
import ActionType from "../dux/actionTypes";
import { usePubSubStore } from "stores/usePubSubStore";
import Topics from "utils/pubSub/topics";

function pubSubHandleRemover(subscriber: any) {
  subscriber.forEach((s: any) => {
    try {
      s.remove();
    } catch {
      //
    }
  });
}

function pubSubHandler(pubSub: any, chatsListDispatcher: Dispatch<any>) {
  const subScriber = new Map();
  if (!pubSub) return subScriber;
  subScriber.set(
    Topics.CREATE_CHANNEL,
    pubSub.subscribe(Topics.CREATE_CHANNEL, (msg: any) => {
      const { channel } = msg;
      chatsListDispatcher({
        type: ActionType.CREATE_CHANNEL,
        payload: channel,
      });
    })
  );
  subScriber.set(
    Topics.USER_BLOCKED,
    pubSub.subscribe(Topics.USER_BLOCKED, (msg: any) => {
      const { blockedUserIds } = msg;
      chatsListDispatcher({
        type: ActionType.ON_USER_BLOCKED,
        payload: blockedUserIds,
      });
    })
  );
  return subScriber;
}

function useHandlePubSubEvents(chatsListDispatcher: Dispatch<any>) {
  const pubSub = usePubSubStore((state) => state.pubSub);

  useEffect(() => {
    const subscriber = pubSubHandler(pubSub, chatsListDispatcher);
    return () => {
      pubSubHandleRemover(subscriber);
    };
  }, [pubSub, chatsListDispatcher]);
}

export default useHandlePubSubEvents;
