import React from "react";
import logo from "img/tokitalk-logo.png";
import { ModalType, useModalStore } from "stores/useModalStore";

function ChatsMain() {
  const setModal = useModalStore((state) => state.setModal);

  return (
    <div className="h-full w-full flex items-center justify-center">
      <div className="flex flex-col items-center text-black">
        <img src={logo} className="w-20 pb-5" alt="" />
        <div className="pb-3 font-normal text-3xl">Your Messages</div>
        <div className="pb-6 font-medium">
          Send private messages to a crypto wallet.
        </div>
        <button
          type="button"
          className="btn-primary btn-sm focus:outline-none"
          onClick={() => setModal(ModalType.ADD_CHAT)}
        >
          Send Message
        </button>
      </div>
    </div>
  );
}

export default ChatsMain;
