import create, { SetState } from "zustand";
import { v4 as uuidv4 } from "uuid";

type ErrorNotification = {
  id: string;
  type: "error";
  message: string;
  visible: boolean;
};

type Notification = ErrorNotification; // Add more notification types here

type NotificationsStore = {
  notifications: Array<Notification>;
  addNotification: (notification: Notification) => void;
  addNewErrorNotification: (message: string) => void;
  hideNotification: (id: string) => void;
};

export const useNotificationsStore = create<NotificationsStore>(
  (set: SetState<NotificationsStore>) => {
    return {
      notifications: [],
      addNotification: (notification) => {
        set((state) => ({
          notifications: [...state.notifications, notification],
        }));
      },
      addNewErrorNotification: (message) => {
        useNotificationsStore.getState().addNotification({
          id: uuidv4(),
          type: "error",
          visible: true,
          message: message,
        });
      },
      hideNotification: (id) => {
        set((state) => ({
          notifications: state.notifications.map((notification) => {
            if (notification.id === id) {
              return {
                ...notification,
                visible: false,
              };
            } else {
              return notification;
            }
          }),
        }));
      },
    };
  }
);
